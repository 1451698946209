import { LoadingOverlay } from "@mantine/core";
import { capitalize } from "lodash";
import {
  useEffect, useLayoutEffect, useRef, useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  useAccount, useContact, useWorkspaceMetricPreferences, useWorkspacePreferences,
} from "../../../../hooks/QueryHooks";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";
import AccountCrumbs from "../components/AccountCrumbs";
import UsersTable from "./components/UserActivityTable";
import UserProfileHeader from "./components/UserProfileHeader";
import UserToolbar from "./components/UserToolbar";
import UserTrendsContainer from "./components/UserTrendsContainer";

const UserDetailsPage = () => {
  const { accountId, userId }: any = useParams();
  const [metric, setMetric] = useState <any>({ id: 3, name: "Last 30 days" });
  const currentWorkspace = useWorkspaceStore((state) => state.workspace);

  const { data: contact, isLoading }: any = useContact({
    workspaceId: currentWorkspace.id,
    contactId: userId,
    enabled: !!userId && !!currentWorkspace?.id,
  });

  const { data: account }: any = useAccount({
    workspaceId: currentWorkspace.id,
    accountId,
    enabled: !!accountId && !!currentWorkspace?.id,
  });

  const prefs: any = useWorkspacePreferences({
    workspaceId: currentWorkspace?.id,
    enabled: !!currentWorkspace?.id,
  });
  const metrics: any = useWorkspaceMetricPreferences({
    workspaceId: currentWorkspace?.id,
    enabled: !!currentWorkspace?.id,
  });

  const [style, setStyle] = useState<any>({ height: 0, marginTop: 0 });

  const divRef = useRef<any>();

  useLayoutEffect(() => {
    const updateHeight = () => {
      if (divRef.current) {
        setStyle({
          height: window.innerHeight - divRef.current.offsetHeight - divRef.current.offsetTop,
          marginTop: divRef.current.offsetHeight + divRef.current.offsetTop,
        });
      }
    };

    // Initial height update
    updateHeight();

    // Add event listener for window resize
    window.addEventListener("resize", updateHeight);

    // Function to observe the height changes of divRef.current
    const observer = new ResizeObserver(updateHeight);
    if (divRef.current) {
      observer.observe(divRef.current);
    }

    // Clean up event listener and observer on component unmount
    return () => {
      window.removeEventListener("resize", updateHeight);
      observer.disconnect();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef.current, divRef.current?.offsetHeight]);

  useEffect(() => {
    document.title = `${contact?.name ? `${capitalize(contact?.name)} | ` : ""}User | Hyperengage`;
  }, [contact?.name]);

  return (
    <div className="min-h-full relative bg-gray-50">
      <LoadingOverlay visible={isLoading} opacity={0.8} zIndex={100} c="main.6" radius="xl" />
      <div ref={divRef} className="absolute left-0 top-0 right-0 z-[2]">
        <AccountCrumbs
          className="mt-2"
          accountName={account?.name || "undefined"}
          userName={contact?.name || "undefined"}
        />
        <UserProfileHeader user={contact} />
      </div>
      <div
        style={style}
        className="py-2 pr-3 relative flex gap-1"
      >
        <div className="px-2">
          <UserTrendsContainer
            prefs={prefs}
            metrics={metrics?.data?.filter((m: any) => m.linked_entity === "users")}
            account={contact}
            currentWorkspace={currentWorkspace}
          />
        </div>
        <div
          className="relative flex flex-col gap-4 overflow-auto border bg-white border-gray-200"
        >
          <UserToolbar metric={metric} setMetric={setMetric} />
          <UsersTable
            metric={metric}
            workspaceId={currentWorkspace?.id}
          />
        </div>
      </div>
    </div>
  );
};

export default UserDetailsPage;
