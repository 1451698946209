const arrayToObject = (
  array: any,
  keyField: any,
  source?: any,
) => array.reduce((obj: any, item: any) => {
  if (source) {
    obj[`${source}.${item[keyField]}`] = item;
  } else { obj[item[keyField]] = item; }
  return obj;
}, {});

export default arrayToObject;
