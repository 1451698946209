/* eslint-disable import/prefer-default-export */
import { authorizedApiClient } from "./config";

type TypeAhead = {
  workspace_identifier: string,
  entity_type: string,
  search_query: string,
  selector_field: string,
  limit?: number,
  offset?: number,
  data_type: string,
  entity_field_type?: string,
};

export const getTypeAhead = async ({
  workspace_identifier,
  entity_type,
  search_query,
  selector_field,
  entity_field_type,
  limit = 100,
  offset = 0,
  data_type,
}: TypeAhead) => authorizedApiClient.post(`/${workspace_identifier}/utils/typeahead`, {
  entity_type,
  entity_field_type,
  search_query,
  selector_field,
  limit,
  offset,
  data_type,
});
