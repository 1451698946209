/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { PencilIcon } from "@heroicons/react/20/solid";
import {
  Box,
  Center,
  Container, Flex,
  Group,
  Radio,
  ScrollArea,
  SegmentedControl,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { SortDirection } from "ka-table";
import {
  useCallback, useEffect, useMemo, useState,
} from "react";
import { useNavigate } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { ReactComponent as BrowserIcon } from "../../../assets/images/browser.svg";
import { ReactComponent as NodeIcon } from "../../../assets/images/node.svg";
import {
  queryClient, useAllAutomations, useAllPlaybooks, useApiKey, useCurrentUser, useTraitsAndMetrics,
} from "../../../hooks/QueryHooks";
import useCheckEvents from "../../../hooks/useCheckEvents";
import { createAutomation } from "../../../services/automation/automationEndpoints";
import { createView } from "../../../services/view/viewEndpoints";
import { getWorkspaceTrendsAgaintQuery, updateWorkspace } from "../../../services/workspace/workspaceEndpoints";
import { ENTITY } from "../../../services/workspace/workspaceEndpoints.enums";
import StepWithProgressbar from "../../../shared/step-with-progressbar/StepWithProgresssbar";
import MButton from "../../../shared/ui/buttons/MButton";
import MultiSelectDropdown from "../../../shared/ui/dropdown/MultiSelectDropdown";
import SortDropdown from "../../../shared/ui/dropdown/SortDropdown";
import BasicSelect from "../../../shared/ui/inputs/select/BasicSelect";
import useGettingStartedStore from "../../../store/getting-started/gettingStartedStore";
import useWorkspaceStore from "../../../store/workspace/workspaceStore";
import { getLargerTimeWindow } from "../../../utils/dateConverter";
import notify from "../../../utils/notify";
import stepSelector from "../../../utils/stepSelector";
import CopyButton from "../../onboarding/native-api/components/CopyButton";
import StepFooter from "../../onboarding/native-api/components/StepFooter";
import NewSignalBody from "../signals/new-signal/components/NewSignalBody";
import GettingStartedViewFilterContainer from "./GettingStartedViewFilterContainer";
import ScheduleDemo from "./ScheduleDemo";

const findStatus = (active: number, current: number) => {
  if (active > current) {
    return "complete";
  }
  if (active < current) {
    return "upcoming";
  }

  return "current";
};

const GettingStartedPage = () => {
  const { workspace, setWorkspace } = useWorkspaceStore();
  const [signalTemplate, setSignalTemplate] = useState<string>("custom");
  const [method, setMethod] = useState<string>("Browser");
  const [activeStep, setActiveStep] = useState<number>(1);
  const [activeModal, setActiveModal] = useState<number>(1);
  const [viewTotal, setViewTotal] = useState<number>(0);

  const updateWorkspaceMutation = useMutation({
    mutationFn: updateWorkspace,
  });

  useEffect(() => {
    if (workspace?.setup_complete) {
      setActiveStep(5);
    }
  }, [workspace?.setup_complete]);

  const {
    signal,
    setName,
    setEntityRelation,
    setTrigger,
    setMetrics,
    setSelectedFields,
    addFilter,
    addPredicate,
    editPredicate,
    deletePredicate,
    deleteFilter,
    clearFilter,
    clearSignal,
    setTimeWindow,
    setSuppressionPeriod,
    createAction,
    deleteAction,
    updateAction,
    setSignalActiveUsers,
    setSignalPaidUsers,
    setSignalHighGrowthAccounts,
    updateView, addViewPredicate, editViewPredicate, deleteViewPredicate,
    setViewMetrics, setViewTimeWindow, setViewSelectedFields, view,
    setPlaybook,
    setResponseData,
  } = useGettingStartedStore();

  const apiKey: any = useApiKey({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  const steps = stepSelector(
    method,
    workspace.unique_identifier,
    apiKey?.data?.js_analytics_key,
  );

  const onClick = (stepNo: number) => {
    setActiveModal((state) => (state === stepNo ? -1 : stepNo));
  };

  const nextHandler = () => {
    setActiveModal(activeStep + 1);
    setActiveStep((state) => (state + 1));
  };

  const prevHandler = () => {
    if (activeStep !== 1) {
      setActiveModal(activeStep - 1);
    }
  };

  const checkEvents: any = useCheckEvents({
    token: apiKey?.data?.id,
    integrationName: "native",
    workspaceId: workspace?.id,
  });

  useEffect(
    () => {
      if (signalTemplate === "custom") {
        clearSignal();
      } else if (signalTemplate === "active") {
        setSignalActiveUsers();
      } else if (signalTemplate === "paid") {
        setSignalPaidUsers();
      } else if (signalTemplate === "highGrowth") {
        setSignalHighGrowthAccounts();
      }
    },
    [signalTemplate],
  );

  const [error, setError] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  const traitsAndMetrics: any = useTraitsAndMetrics({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  const { data: user }: any = useCurrentUser({});

  const { data: playBookData }: any = useAllPlaybooks({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  useAllAutomations({
    workspaceId: workspace?.id,
    automationType: "signal",
    enabled: !!workspace?.id,
  });

  const playbooks = useMemo(() => playBookData?.map((playbook: any) => ({
    label: playbook?.title,
    value: playbook?.id,
  })), [playBookData?.length]);

  const createSignalMutation = useMutation({
    mutationFn: createAutomation,
  });

  const getTotalMutation = useMutation({
    mutationFn: getWorkspaceTrendsAgaintQuery,
  });

  const handleFilterAdd = () => {
    const selectedFilters = signal
      .filters.predicates[signal.filters.predicates.length - 1]?.predicates || [];
    if (selectedFilters.length === 0 || selectedFilters[0].field !== "") {
      addFilter({
        query_type: "AND",
        predicates: [{
          field: "",
          operator: "",
          value: "",
          type: "",
          entity_type: "column",
          entity_field_type: "default",
        }],
      });
    }
  };

  const handlePredicateAdd = (filterId?: any) => {
    const selectedFilters = signal.filters.predicates[filterId].predicates;
    if (selectedFilters.length === 0 || selectedFilters[selectedFilters.length - 1].field !== "") {
      addPredicate(
        {
          field: "",
          operator: "",
          value: "",
          type: "",
          entity_type: "column",
          entity_field_type: "default",
        },
        filterId,
      );
    }
  };

  const handlePredicateClear = (filterId?: any) => {
    deleteFilter(filterId);
  };

  const handlePredicateChange = (
    field: any,
    operator: string,
    value: any,
    type: string,
    entity_type?: string,
    id?: any,
    filterId?: any,
    time_window?: string,
  ) => {
    if (field !== "") {
      const fieldName = field?.object_definition ? `${field?.object_definition?.slug_name}.${field?.data_key_slug}` : field?.data_key_slug;
      editPredicate({
        field: fieldName,
        operator,
        value,
        type: field?.data_type,
        entity_type: entity_type || "column",
        entity_field_type: field?.entity_type || "default",
        time_window,
      }, id, filterId);
      const selectedColumns = signal?.selected_fields || [];
      const selectedMetrics = signal?.metrics || [];
      let newColumns: any = selectedColumns;
      const newMetrics: any = (!selectedMetrics.includes(fieldName) && entity_type !== "column" && fieldName !== "") ? [...selectedMetrics, fieldName] : selectedMetrics;
      if (signal?.entity_relation === "accounts" && fieldName.startsWith("accounts.")) {
        newColumns = (!selectedColumns.find((item) => item.name === fieldName) && entity_type === "column") ? [...selectedColumns, {
          name: fieldName,
          field_type: field?.entity_type,
          data_type: field?.data_type,
        }] : selectedColumns;
        setSelectedFields(newColumns);
      } else if (signal?.entity_relation === "users" && fieldName.startsWith("users.")) {
        newColumns = (!selectedColumns.find((item) => item.name === fieldName) && entity_type === "column") ? [...selectedColumns, {
          name: fieldName,
          field_type: field?.entity_type,
          data_type: field?.data_type,
        }] : selectedColumns;
        setSelectedFields(newColumns);
      }
      setMetrics(newMetrics);
      setTimeWindow(getLargerTimeWindow(signal?.time_window, time_window));
    }
  };

  useEffect(() => {
    if (signal?.filters?.predicates?.[0]?.predicates?.[0]?.field !== "") {
      getTotalMutation.mutate({
        workspaceId: workspace?.id,
        entityType: signal?.entity_relation === "accounts" ? ENTITY.ACCOUNTS : ENTITY.USERS,
        query: {
          include_count: true,
          page: 1,
          per_page: 10,
          filter: {
            ...signal?.filters,
            predicates: signal?.filters?.predicates?.map((fil: any) => ({
              ...fil,
              predicates: fil?.predicates.filter((item: any) => item.field !== ""),
            })),
          },
          selected_fields: signal?.selected_fields?.find((item:any) => item.data_key_slug === "last_seen") ? signal?.selected_fields : (signal?.selected_fields || []).concat([{ name: signal?.entity_relation === "accounts" ? "accounts.last_seen" : "users.last_seen", field_type: "default", data_type: "Date" }]),
          sort: {
            field: signal?.entity_relation === "accounts" ? "accounts.last_seen" : "users.last_seen",
            order: "desc",
            type: "property",
            data_type: "Date",
            field_type: "default",
          },
          metrics: signal?.metrics,
          time_window: signal?.time_window,
          time_zone: workspace?.workspace_time_zone,
        },
      }, {
        onSuccess: (data: any) => {
          setResponseData(data?.data);
          setTotal(data?.total_results);
        },
        onError: () => {
          setTotal(0);
          notify({
            messageList: ["Unknown error while applying filter"],
            type: "failure",
          });
        },
      });
    }
  }, [signal?.filters]);

  const handlePredicateRemove = (id: number, filterId?: any) => {
    deletePredicate(id, filterId);
  };

  const navigate = useNavigate();

  const onTimeWindowChange = (value: any) => {
    setTimeWindow(value);
  };

  const onSignalSubmit = () => {
    const slackEnabled = signal?.automation_actions?.actions?.find((action: any) => action?.type === "send_to_slack");
    const emailEnabled = signal?.automation_actions?.actions?.find((action: any) => action?.type === "send_to_email");
    if (!signal?.name || !signal?.entity_relation || !signal?.trigger
      || !signal?.filters?.predicates[0]?.predicates?.length
      || !signal?.automation_actions?.actions?.length
      || !signal?.automation_actions?.suppression_period
      || !signal?.time_window
      || (!!slackEnabled && ((slackEnabled?.message_type === "channel" && !slackEnabled?.channel_id)
       || (slackEnabled?.message_type === "direct" && !slackEnabled?.slack_user_id)))
      || (!!emailEnabled && (!emailEnabled?.to || !emailEnabled?.subject))
      || (!signal?.filters?.predicates?.find((fil: any) => fil?.predicates?.find((item: any) => item?.field?.includes("metric"))))) {
      setError(true);
      notify({
        messageList: ["Kindly resolve errors in form fields"],
        type: "warning",
      });
    } else {
      createSignalMutation.mutate({
        ...signal,
        time_zone: workspace?.workspace_time_zone,
        status: "active",
        workspace_id: workspace?.id,
        owner_id: user?.user_id,
      }, {
        onSuccess: (data: any) => {
          queryClient.setQueryData(["all_automations", workspace?.id, signal?.automation_type], (oldData: any) => ({
            ...oldData,
            data: [
              ...oldData.data,
              data?.data,
            ],
          }));
          if (process.env.REACT_APP_HE_WORKSPACE_KEY && process.env.REACT_APP_HE_DATA_KEY) {
            window?.hyperengage(
              "track",
              "new_signal_created",
              {
                properties: {
                  name: signal?.name,
                  entity_relation: signal?.entity_relation,
                  trigger: signal?.trigger,
                  actions: signal?.automation_actions?.actions?.map((action: any) => action?.type),
                  time_zone: workspace?.workspace_time_zone,
                  status: "active",
                  workspace_id: workspace?.id,
                  owner_id: user?.user_id,
                },
              },
            );
          }
          notify({
            heading: "New Signal Created",
            type: "success",
            messageList: ["You have successfully created a new Signal."],
          });
          setSignalTemplate("custom");
        },
        onError: (err: any) => {
          if (err.response?.status !== 0) {
            notify({
              heading: "Unknown error.",
              type: "failure",
              messageList: [
                "An unknown error occurred while creating the signal. Please try again later or contact our support team for assistance.",
              ],
            });
          }
        },
      });
    }
  };

  const [showHidden, setShowHidden] = useState(false);
  const [showSort, setShowSort] = useState(false);

  const timeframes = useMemo(() => ([
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "Last 90 days",
    "Last 180 days",
    "Last 365 days",
    "All time",
  ]).map((key, id) => ({ id: id + 1, label: key, name: key.toLowerCase().split(" ").join("_") })), []);

  const createViewMutation = useMutation({
    mutationFn: createView,
  });

  const selectedTimeFrame = useMemo(
    () => timeframes.find((item) => item.name === view?.metrics_time_frame) || timeframes[0],
    [view?.metrics_time_frame],
  );

  const selectedFilters = useMemo(
    () => view?.filters?.predicates || [],
    [view?.filters?.predicates],
  );
  const selectedColumns = useMemo(
    () => view?.selected_columns || [],
    [view?.selected_columns],
  );

  const selectedMetrics = useMemo(
    () => view?.metrics || [],
    [view?.metrics],
  );

  const sort = useMemo(
    () => {
      const selectedField = { ...traitsAndMetrics?.data?.accountTraits?.obj, ...traitsAndMetrics?.data?.accountMetrics?.obj }[view?.sort_fields?.field];
      return {
        name: selectedField?.display_name,
        type: selectedField?.data_type,
        attributeType: selectedField?.attribute_type,
        value: view?.sort_fields?.field,
        order: view?.sort_fields?.order,
        sortType: view?.sort_fields?.type,
        dataType: selectedField?.data_type,
        fieldType: selectedField?.entity_type || "default",
      };
    },
    [view?.sort_fields, traitsAndMetrics?.data?.accountTraits?.list],
  );

  const filteredTraits = useMemo(
    () => traitsAndMetrics?.data?.accountTraits?.list?.filter((item: any) => !item.exclude_display),
    [traitsAndMetrics?.data],
  );

  const filteredMetrics = useMemo(
    () => traitsAndMetrics?.data?.accountMetrics?.list?.filter((item: any) => !item.exclude_display),
    [traitsAndMetrics?.data],
  );

  const handleNewView = () => {
    createViewMutation.mutate({
      ...view,
      filters: { ...view?.filters, predicates: view?.filters?.predicates?.filter((item: any) => item.field !== "") },
      owner_id: user?.user_id,
      workspace_id: workspace?.id,
      is_public: true,
      is_precreated: false,
    }, {
      onSuccess: (data: any) => {
        queryClient.setQueryData(["views", workspace?.id], (oldData: any) => ({
          ...oldData,
          views: [...oldData.views,
            data?.data,
          ],
        }));
        if (process.env.REACT_APP_HE_WORKSPACE_KEY && process.env.REACT_APP_HE_DATA_KEY) {
          window?.hyperengage(
            "track",
            "new_view_created",
            {
              properties: {
                view_name: view?.view_name,
                workspace_id: workspace?.id,
                owner_id: user?.user_id,
                is_public: view?.is_public,
              },
            },
          );
        }
        notify({
          heading: "New View Created",
          type: "success",
          messageList: ["You have successfully created a new view."],
        });
        navigate(`/home/${workspace?.unique_identifier}/view/${data?.data?.id}`);
      },
      onError: (er: any) => {
        if (er.response?.status !== 0) {
          notify({
            heading: "Unknown error.",
            type: "failure",
            messageList: [
              "An unknown error occurred while creating the new view. Please try again later or contact our support team for assistance.",
            ],
          });
        }
      },
    });
  };

  const handleViewHidden = () => {
    setShowHidden((state) => !state);
    setShowSort(false);
  };

  const handleViewSort = () => {
    setShowSort((state) => !state);
    setShowHidden(false);
  };

  const handleViewTimeFrame = (timeframe: any) => {
    updateView({
      metrics_time_frame: timeframe?.name,
    });
  };

  const handleViewPredicateAdd = () => {
    addViewPredicate(
      {
        field: "",
        operator: "",
        value: "",
        type: "",
        entity_type: "column",
        entity_field_type: "default",
      },
    );
  };

  const handleViewPredicateClear = () => {
    updateView({
      filters: {
        query_type: "AND",
        predicates: [],
      },
    });
  };

  const handleViewPredicateChange = (
    field: any,
    operator: string,
    value: any,
    type: string,
    entity_type: string,
    arrayId: number,
    filterId: number,
    time_window?: string,
  ) => {
    if (field !== "") {
      const fieldName = field?.object_definition ? `${field?.object_definition?.slug_name}.${field?.data_key_slug}` : field?.data_key_slug;
      editViewPredicate({
        field: fieldName,
        operator,
        value,
        type: field?.data_type,
        entity_type: entity_type || "column",
        entity_field_type: field?.entity_type || "default",
        time_window,
      }, arrayId);

      const predicates = [...view.filters.predicates];
      predicates[arrayId] = {
        field: fieldName,
        operator,
        value,
        type: field?.data_type,
        entity_type: entity_type || "column",
        entity_field_type: field?.entity_type || "default",
        time_window,
      };
      let newColumns: any = selectedColumns;
      const newMetrics: any = (!selectedMetrics.includes(fieldName) && entity_type !== "column" && fieldName !== "") ? [...selectedMetrics, fieldName] : selectedMetrics;
      if (view?.entity_relation === "accounts" && fieldName?.startsWith("accounts.")) {
        newColumns = (!selectedColumns.find((item: any) => item.name === fieldName) && entity_type === "column") ? [...selectedColumns, {
          name: fieldName,
          field_type: field?.entity_type,
          data_type: field?.data_type,
        }] : selectedColumns;
        setViewSelectedFields(newColumns);
      } else if (view?.entity_relation === "users" && fieldName?.startsWith("users.")) {
        newColumns = (selectedColumns.find((item: any) => item.name === fieldName) && entity_type === "column") ? [...selectedColumns, {
          name: fieldName,
          field_type: field?.entity_type,
          data_type: field?.data_type,
        }] : selectedColumns;
        setViewSelectedFields(newColumns);
      }
      setViewMetrics(newMetrics);
      setViewTimeWindow(getLargerTimeWindow(view?.metrics_time_frame, time_window));
      getTotalMutation.mutate({
        workspaceId: workspace?.id,
        entityType: ENTITY.ACCOUNTS,
        query: {
          include_count: true,
          page: 1,
          per_page: 10,
          filter: {
            query_type: "AND",
            predicates: predicates?.filter((item: any) => item.field !== "") || {},
          },
          selected_fields: newColumns?.length ? newColumns : selectedColumns,
          sort: {
            field: view?.sort_fields?.field,
            order: view?.sort_fields?.order === SortDirection.Ascend ? "asc" : "desc",
            type: ({ ...traitsAndMetrics?.data?.accountTraits?.obj, ...traitsAndMetrics?.data?.accountMetrics?.obj }[view?.sort_fields?.field]?.attribute_type === "metric_traits" ? "growthval" : "property"),
            data_type: ({ ...traitsAndMetrics?.data?.accountTraits?.obj, ...traitsAndMetrics?.data?.accountMetrics?.obj }[view?.sort_fields?.field]?.data_type),
            field_type: ({ ...traitsAndMetrics?.data?.accountTraits?.obj, ...traitsAndMetrics?.data?.accountMetrics?.obj }[view?.sort_fields?.field]?.entity_type),
          },
          metrics: newMetrics,
          time_window: view?.metrics_time_frame,
          time_zone: workspace?.workspace_time_zone,
        },
      }, {
        onSuccess: (data: any) => {
          setViewTotal(data?.total_results);
        },
        onError: () => {
          setViewTotal(0);
          notify({
            messageList: ["Unknown error while applying filter"],
            type: "failure",
          });
        },
      });
    }
  };

  const handleViewPredicateRemove = (currentId: number) => {
    deleteViewPredicate(currentId);
  };

  const handleEntityChange = (value: "accounts" | "users") => {
    if (value === "accounts" && signal.entity_relation !== "accounts") {
      setSelectedFields([{
        name: "accounts.name",
        field_type: "default",
        data_type: "Text",
      }, {
        name: "accounts.created_at",
        field_type: "default",
        data_type: "Date",
      }, {
        name: "accounts.last_seen",
        field_type: "default",
        data_type: "Date",
      }, {
        name: "accounts.external_id",
        field_type: "default",
        data_type: "Text",
      }]);
    } else if (value === "users" && signal.entity_relation !== "users") {
      setSelectedFields([
        {
          name: "users.name",
          field_type: "default",
          data_type: "Text",
        },
        {
          name: "users.email",
          field_type: "default",
          data_type: "Text",
        },
        {
          name: "users.created_at",
          field_type: "default",
          data_type: "Date",
        },
        {
          name: "users.last_seen",
          field_type: "default",
          data_type: "Date",
        },
        {
          name: "users.external_id",
          field_type: "default",
          data_type: "Text",
        },
      ]);
    }
    setEntityRelation(value);
  };

  const onSelectSort = useCallback(
    (field: any, dir: any) => {
      updateView({
        sort_fields: {
          field: field?.value, order: dir?.order, type: dir?.type || "property", data_type: field?.dataType, field_type: field?.fieldType,
        },
      });
    },
    [view],
  );

  const onSelectColumn = (item: any) => {
    // Checking if the column is a metric, then setting it in metrics
    if (item.attribute_type === "metric_traits") {
      updateView({
        ...view,
        metrics: selectedMetrics.includes(item.data_key_slug)
          ? selectedMetrics.filter((name: any) => name !== item.data_key_slug)
          : [...selectedMetrics, item.data_key_slug],
      });
      // Else we are setting it in selected columns
    } else {
      updateView({
        ...view,
        selected_columns: selectedColumns?.find((col: any) => col.name === `${item.object_definition.slug_name}.${item.data_key_slug}`)
          ? selectedColumns.filter((col: any) => col.name !== `${item.object_definition.slug_name}.${item.data_key_slug}`)
          : [...selectedColumns, {
            name: `${item.object_definition.slug_name}.${item.data_key_slug}`,
            field_type: item.entity_type,
            data_type: item.data_type,
          }],
      });
    }
  };
  return (
    <Container fluid className="px-12 py-8 overflow-auto w-full relative h-full bg-gray-100 ">
      <Flex justify="space-between" align="center">
        <Text p={16} pt={0} pl={0} my={8} c="text.9" size={30} fw={700}>
          Getting Started
        </Text>
        <MButton
          leftIcon={<IconCheck />}
          size="xs"
          color="purple"
          onClick={() => {
            updateWorkspaceMutation.mutate({
              workspace_id: workspace?.id,
              getting_started_guide_dismissed: true,
            }, {
              onSuccess: () => {
                queryClient.setQueryData(["workspace", workspace?.id], (oldData: any) => ({
                  ...oldData,
                  data: {
                    ...oldData?.data,
                    getting_started_guide_dismissed: true,
                  },
                }));
                setWorkspace((state: any) => ({
                  ...state,
                  getting_started_guide_dismissed: true,
                }));
              },
            });
            navigate(`/home/${workspace?.unique_identifier}/headquarters`);
          }}
        >
          Mark as done
        </MButton>
      </Flex>
      <ScheduleDemo />
      <Flex className="w-full self-stretch" gap={12}>
        <Container fluid p={16} bg="white" className="rounded-md w-full shadow-sm">
          <Text c="text.6" weight={500} mb={2}>
            Your setup list
          </Text>
          <Text c="text.5" size="sm" mb={20}>
            Get your account ready and start receiving targeted signals for all of your customer journeys.
          </Text>
          <nav aria-label="Progress" className="flex flex-col h-full">
            <ScrollArea className="h-[calc(100vh-220px)]">
              <ol className="overflow-auto flex flex-col gap-4 p-7 pb-0">
                <StepWithProgressbar
                  status="complete"
                  isOpen={activeModal === 1 && !workspace?.setup_complete}
                  onClick={onClick}
                  stepNo={1}
                  desc="Easily use our lightweight privacy-friendly tracking script to start sending data over to your workspace."
                  title="Connect a data source"
                >
                  <div className="flex flex-col gap-3">
                    <SegmentedControl
                      color="main.6"
                      size="sm"
                      value={method}
                      onChange={setMethod}
                      data={[
                        {
                          value: "Browser",
                          label: (
                            <Center>
                              <BrowserIcon className="w-4 h-4" />
                              <Box ml={10}>Browser</Box>
                            </Center>
                          ),
                        },
                        {
                          value: "Node",
                          label: (
                            <Center>
                              <NodeIcon className="w-4 h-4" />
                              <Box ml={10}>Node</Box>
                            </Center>
                          ),
                        },
                      ]}
                    />
                    <SyntaxHighlighter showLineNumbers language="html" customStyle={{ fontSize: "13px" }} style={docco}>
                      {steps[0].script}
                    </SyntaxHighlighter>
                    <CopyButton textToCopy={steps[0].script} />
                    {steps[0]?.script2 && (
                    <>
                      <SyntaxHighlighter showLineNumbers language="html" customStyle={{ fontSize: "13px" }} style={docco}>
                        {steps[0].script2}
                      </SyntaxHighlighter>
                      <CopyButton textToCopy={steps[0].script2} />
                    </>
                    )}
                    <StepFooter
                      helpTo="https://docs.hyperengage.io/quickstart/install"
                      nextHandler={nextHandler}
                      backHandler={prevHandler}
                      backButtonVisible={false}
                    />
                  </div>
                </StepWithProgressbar>
                <StepWithProgressbar
                  status={findStatus(activeStep, 2)}
                  isOpen={activeModal === 2 && !workspace?.setup_complete}
                  onClick={onClick}
                  stepNo={2}
                  desc={
                    (
                      <>
                        After connecting your data source with the base tracking script send over your account/company data
                        {" "}
                        <Tooltip
                          multiline
                          classNames={{
                            tooltip: "text-xs",
                          }}
                          position="bottom"
                          withArrow
                          label="Accounts or Companies depend on your data model. Generally they are groups of users that you sell to which, collectively, can be a workspace, organization or accounts."
                        >
                          <Text className="cursor-help italic" c="main.8" weight={500} span>?</Text>
                        </Tooltip>
                      </>
      )
                  }
                  title={steps[1].title}
                >
                  <div className="flex flex-col gap-3">
                    <SyntaxHighlighter showLineNumbers language="javascript" customStyle={{ fontSize: "13px" }} style={docco}>
                      {steps[1].script}
                    </SyntaxHighlighter>
                    <CopyButton textToCopy={steps[1].script} />
                    {checkEvents.data?.group?.length ? (
                      <>
                        <h3 className="text-gray-500">Account data received successfully. Check preview below :</h3>
                        <SyntaxHighlighter showLineNumbers language="javascript" customStyle={{ fontSize: "13px" }} style={docco}>
                          {Object.entries(checkEvents.data?.group[0]).map(([key, val]) => (`${key}: "${val}"`)).join("\n")}
                        </SyntaxHighlighter>
                      </>
                    ) : (
                      <div className="flex flex-col gap-2 items-center justify-center bg-violet-50 p-3">
                        <span className="flex h-3 w-3 relative">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75" />
                          <span className="relative inline-flex rounded-full h-3 w-3 bg-purple-500" />
                        </span>
                        <p className="text-gray-700 text-sm">Waiting to receive account events...</p>
                      </div>
                    )}
                    <StepFooter
                      helpTo="https://docs.hyperengage.io/quickstart/identify"
                      nextHandler={nextHandler}
                      backHandler={prevHandler}
                    />
                  </div>
                </StepWithProgressbar>
                <StepWithProgressbar
                  status={findStatus(activeStep, 3)}
                  isOpen={activeModal === 3 && !workspace?.setup_complete}
                  onClick={onClick}
                  stepNo={3}
                  desc={steps[2].desc}
                  title={steps[2].title}
                >
                  <div className="flex flex-col gap-3">
                    <SyntaxHighlighter showLineNumbers language="javascript" customStyle={{ fontSize: "13px" }} style={docco}>
                      {steps[2].script}
                    </SyntaxHighlighter>
                    <CopyButton textToCopy={steps[2].script} />

                    {checkEvents.data?.identify?.length ? (
                      <>
                        <h3 className="text-gray-500">User data received successfully. Check preview below :</h3>
                        <SyntaxHighlighter showLineNumbers language="html" customStyle={{ fontSize: "13px" }} style={docco}>
                          {Object.entries(checkEvents.data?.identify[0]).map(([key, val]) => (`${key}: "${val}"`)).join("\n")}
                        </SyntaxHighlighter>
                      </>
                    ) : (
                      <div className="flex flex-col gap-2 items-center justify-center bg-violet-50 p-3">
                        <span className="flex h-3 w-3 relative">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75" />
                          <span className="relative inline-flex rounded-full h-3 w-3 bg-purple-500" />
                        </span>
                        <p className="text-gray-700 text-sm">Waiting to receive user events...</p>
                      </div>
                    )}
                    <StepFooter
                      helpTo="https://docs.hyperengage.io/quickstart/identify"
                      nextHandler={nextHandler}
                      backHandler={prevHandler}
                    />
                  </div>
                </StepWithProgressbar>
                <StepWithProgressbar
                  status={findStatus(activeStep, 4)}
                  isOpen={activeModal === 4 && !workspace?.setup_complete}
                  onClick={onClick}
                  stepNo={4}
                  desc={steps[3].desc}
                  title={steps[3].title}
                >
                  <div className="flex flex-col gap-3">
                    <SyntaxHighlighter showLineNumbers language="javascript" customStyle={{ fontSize: "13px" }} style={docco}>
                      {steps[3].script}
                    </SyntaxHighlighter>
                    <CopyButton textToCopy={steps[3].script} />
                    {checkEvents.data?.track?.length ? (
                      <>
                        <h3 className="text-gray-500">Events data received successfully. Check preview below :</h3>
                        <SyntaxHighlighter showLineNumbers language="javascript" customStyle={{ fontSize: "13px" }} style={docco}>
                          {Object.entries(checkEvents.data?.track[0]).map(([key, val]) => (`${key}: "${val}"`)).join("\n")}
                        </SyntaxHighlighter>
                      </>
                    ) : (
                      <div className="flex flex-col gap-2 items-center justify-center bg-violet-50 p-3">
                        <span className="flex h-3 w-3 relative">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75" />
                          <span className="relative inline-flex rounded-full h-3 w-3 bg-purple-500" />
                        </span>
                        <p className="text-gray-700 text-sm">Waiting to receive track events...</p>
                      </div>
                    )}
                    <StepFooter
                      nextHandler={nextHandler}
                      backHandler={prevHandler}
                      helpTo="https://docs.hyperengage.io/quickstart/track"
                    />
                  </div>
                </StepWithProgressbar>
                <StepWithProgressbar
                  status={findStatus(activeStep, 5)}
                  isOpen={activeModal === 5}
                  onClick={onClick}
                  stepNo={5}
                  desc="Signals help you monitor key points in your user's journey so you can drive action."
                  title="Create your first signal"
                >
                  <div className="flex flex-col gap-3">
                    <Radio.Group
                      name="signalTemplate"
                      value={signalTemplate}
                      onChange={setSignalTemplate}
                      label="Signal Template"
                      description="Create your own custom signal or use one of our prebuilt templates provided below"
                      withAsterisk
                    >
                      <Group mt="xs" align="center">
                        <Radio value="custom" label="Custom" />
                        <Radio value="active" label="Active Users" />
                        <Radio value="paid" label="Paid accounts" />
                        <Radio value="highGrowth" label="High growth accounts" />
                      </Group>
                    </Radio.Group>
                    <NewSignalBody
                      setSelectedFields={setSelectedFields}
                      height="full"
                      containerClass="!p-0 !mt-1"
                      clearFilter={clearFilter}
                      clearSignal={clearSignal}
                      onTimeWindowChange={onTimeWindowChange}
                      handlePredicateAdd={handlePredicateAdd}
                      handlePredicateClear={handlePredicateClear}
                      handlePredicateRemove={handlePredicateRemove}
                      handlePredicateChange={handlePredicateChange}
                      handleFilterAdd={handleFilterAdd}
                      setPlaybook={setPlaybook}
                      onSignalSubmit={onSignalSubmit}
                      error={error}
                      total={total}
                      traitsAndMetrics={traitsAndMetrics}
                      signal={signal}
                      setName={setName}
                      setEntityRelation={handleEntityChange}
                      setTrigger={setTrigger}
                      setSuppressionPeriod={setSuppressionPeriod}
                      createAction={createAction}
                      deleteAction={deleteAction}
                      updateAction={updateAction}
                      playbooks={playbooks}
                    />
                    <Flex justify="center" gap={5} className="mt-2 p-4 bg-gray-50">
                      <MButton color="purple" onClick={onSignalSubmit}>Save and activate</MButton>
                    </Flex>
                    <StepFooter
                      nextHandler={nextHandler}
                      backHandler={prevHandler}
                    />
                  </div>
                </StepWithProgressbar>
                <StepWithProgressbar
                  status={findStatus(activeStep, 6)}
                  isOpen={activeModal === 6}
                  onClick={onClick}
                  last
                  stepNo={6}
                  desc="Views help you segment your accounts and users so you can easily monitor their health."
                  title="Create your first custom view"
                >
                  <div className="flex flex-col gap-3 min-h-[330px]">
                    <div className="flex py-2 gap-12 items-end justify-between">
                      <TextInput
                        value={view?.name}
                        onChange={(event) => updateView({ view_name: event.currentTarget.value })}
                        classNames={{
                          label: "text-gray-500 font-bold mb-1",
                        }}
                        label="View Name"
                        className="w-full"
                      />
                      <div className="flex gap-2 flex-wrap lg:flex-nowrap items-end">
                        <MultiSelectDropdown
                          selected={[...selectedColumns, ...selectedMetrics]}
                          setSelected={onSelectColumn}
                          isOpen={showHidden}
                          setIsOpen={setShowHidden}
                          list={filteredTraits}
                          metricsList={filteredMetrics}
                          position="bottom-end"
                        >
                          <div>
                            <MButton
                              size="sm"
                              color="white"
                              onClick={handleViewHidden}
                              leftIcon={<PencilIcon className="h-4 w-4" />}
                            >
                              {`Edit Columns ${
                                view?.selected_columns?.length
                                  ? ` (${
                                    view?.selected_columns?.filter(
                                      (item: any) => !traitsAndMetrics?.data?.accountTraits?.obj[item]?.exclude_display,
                                    ).length
                                  })`
                                  : ""
                              }`}
                            </MButton>
                          </div>
                        </MultiSelectDropdown>
                        {sort?.name && (
                        <SortDropdown
                          selectedColumns={[...selectedColumns, ...selectedMetrics, "last_seen"]}
                          selectSort={sort}
                          isOpen={showSort}
                          setIsOpen={setShowSort}
                          list={[...filteredTraits, ...filteredMetrics]}
                          obj={{ ...traitsAndMetrics?.data?.accountTraits?.obj, ...traitsAndMetrics?.data?.accountMetrics?.obj }}
                          onDone={onSelectSort}
                        >
                          <MButton
                            size="sm"
                            color="white"
                            onClick={handleViewSort}
                          >
                            {`Sort: ${
                              traitsAndMetrics?.data?.accountTraits?.obj ? { ...traitsAndMetrics?.data?.accountTraits?.obj, ...traitsAndMetrics?.data?.accountMetrics?.obj }[sort?.value]?.display_name : ""
                            }`}
                          </MButton>
                        </SortDropdown>
                        )}
                        <div className="items-center">
                          <p className="text-xs text-gray-500 mr-2">Show Usage From: </p>
                          <BasicSelect
                            textClass="text-gray-600 text-xs py-0.5"
                            containerClass="min-w-[140px]"
                            placeholder="Timeframe"
                            list={timeframes}
                            selectedItem={selectedTimeFrame}
                            setSelectedItem={handleViewTimeFrame}
                          />
                        </div>
                      </div>
                    </div>
                    <GettingStartedViewFilterContainer
                      disabled={!view?.view_name}
                      onSave={handleNewView}
                      total={viewTotal}
                      selectedFilters={selectedFilters}
                      handleAdd={handleViewPredicateAdd}
                      handleClear={handleViewPredicateClear}
                      handleRemove={handleViewPredicateRemove}
                      time_window={view?.metrics_time_frame}
                      handleFilter={handleViewPredicateChange}
                      filterId={0}
                      entityType={
                          view?.entity_relation === "accounts"
                            ? "accounts"
                            : "users"
                        }
                    />
                    <StepFooter
                      nextHandler={nextHandler}
                      backHandler={prevHandler}
                    />
                  </div>
                </StepWithProgressbar>
              </ol>
            </ScrollArea>
          </nav>
        </Container>
      </Flex>
    </Container>
  );
};

export default GettingStartedPage;
