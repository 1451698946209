/* eslint-disable @typescript-eslint/no-unused-vars */
import { Paper, Text } from "@mantine/core";
import Nango from "@nangohq/frontend";
import { useNavigate } from "react-router-dom";
import { useIntegrationApp } from "@integration-app/react";
import { useState } from "react";
import { ReactComponent as HubspotIcon } from "../../../assets/images/hubspot.svg";
import { queryClient } from "../../../hooks/QueryHooks";
import IntegrationLayout from "../../../shared/integration/IntegrationLayout";
import MButton from "../../../shared/ui/buttons/MButton";
import useWorkspaceStore from "../../../store/workspace/workspaceStore";
import notify from "../../../utils/notify";

const HubspotPage = () => {
  const workspace: any = useWorkspaceStore((state) => state.workspace);
  const navigate = useNavigate();
  const integrationApp = useIntegrationApp();
  const [integrationLoading, setIntegrationLoading] = useState<boolean>(false);

  const hubspotClick = async () => {
    setIntegrationLoading(true);
    const loading = await integrationApp.integration("hubspot").connect().then((res) => res);
    setIntegrationLoading(false);
  };

  return (
    <IntegrationLayout
      title="Hubspot"
      description="Connect HubSpot to Hyperengage to streamline your customer engagement, sync data, automate workflows, and gain real-time insights into your interactions."
      workingDesc="The Hyperengage Integration for Hubspot helps you stay up-to-date with your prospects and customers by delivering real-time notifications about interactions. Subscribe to the account and contact lists that matter most to keep your team in sync."
      IntegrationIcon={HubspotIcon}
    >
      <Paper p="md" withBorder className="flex flex-col bg-gray-50 gap-0.5 items-start">
        <Text weight={500}>Connect your Hubspot Account</Text>
        <Text c="text.5">Please connect your Hubspot account inorder to get started.</Text>
        <MButton loading={integrationLoading} color="orange" mt={10} onClick={() => { hubspotClick(); }}>
          Log in with Hubspot
        </MButton>
      </Paper>
      <Paper p="md" withBorder className="flex flex-col mt-3 items-start bg-gray-50 gap-0.5">
        <Text weight={500}>Sync Sources</Text>
        <Text c="text.5">Select the Hubspot sources you want to sync with Hyperengage</Text>
        <MButton
          color="orange"
          mt={10}
          onClick={
          () => {
            integrationApp.connection("hubspot").fieldMapping("hubspot").then((res) => res);
          }
}
        >
          Sync Hubspot
        </MButton>
      </Paper>
      <Paper p="md" withBorder className="flex flex-col items-start mt-3 bg-gray-50 gap-0.5">
        <Text weight={500}>Field Mapping</Text>
        <Text c="text.5">Two way field mapping between Hyperengage and Hubspot</Text>
        <MButton color="orange" mt={10} onClick={() => { hubspotClick(); }}>
          Configure Mapping
        </MButton>
      </Paper>
      <div className="fixed right-10 bottom-5">
        <MButton color="purple" onClick={() => { }}>
          Complete Integration
        </MButton>
      </div>
    </IntegrationLayout>
  );
};

export default HubspotPage;
