import { AxiosResponse } from "axios";
import { authorizedApiClient } from "../config";
import {
  CreateView,
  CreateViewResponse,
  GetViewByID,
  GetViewByIdResponse,
  GetViews,
  PinnedView,
  PinnedViewResponse,
  UpdateView,
  ViewsResponse,
} from "./viewEndpoints.interface";

export const createView = async ({
  view_name,
  icon,
  is_public,
  filters,
  entity_relation,
  metrics_time_frame = "last_30_days",
  selected_columns,
  workspace_id,
  owner_id,
  sort_fields,
  metrics,
  is_precreated,
  page_limit = 10,
}: CreateView) => authorizedApiClient.post<CreateViewResponse>(`/${workspace_id}/views`, {
  view_name,
  icon,
  is_public,
  filters,
  entity_relation,
  is_precreated,
  metrics_time_frame,
  selected_columns,
  sort_fields,
  metrics,
  page_limit,
  workspace_id,
  owner_id,
});

export const updateViewInApi = async ({
  view_name,
  view_description = null,
  icon = null,
  workspace_id,
  is_public,
  filters = {},
  entity_relation,
  metrics_time_frame,
  selected_columns,
  sort_fields,
  metrics,
  page_limit = 1,
  id,
}: UpdateView) => authorizedApiClient.put<UpdateView>(`/${workspace_id}/views/${id}`, {
  view_name,
  view_description,
  icon,
  is_public,
  filters,
  entity_relation,
  metrics_time_frame,
  selected_columns,
  sort_fields,
  metrics,
  page_limit,
  id,
});

export const getViewById = (
  {
    workspace_identifier,
    view_id,
  }: GetViewByID,
) => authorizedApiClient.get<GetViewByIdResponse>(`/api/v1/${workspace_identifier}/views/${view_id}`);

export const getViews = async ({ workspace_identifier }: GetViews): Promise<AxiosResponse<ViewsResponse>> => authorizedApiClient.get<ViewsResponse>(`/${workspace_identifier}/views/all`);

export const pinView = async (
  workspaceId: string,
  pinnedView: PinnedView,
) => authorizedApiClient.post<PinnedViewResponse>(
  `/api/v1/${workspaceId}/pinned_views`,
  pinnedView,
);

export const removePinnedView = (
  workspaceId: string,
  viewId: string,
) => authorizedApiClient.delete<PinnedViewResponse>(`/api/v1/${workspaceId}/pinned_views/${viewId}`);
