/* eslint-disable react-hooks/exhaustive-deps */
import { NumberInput } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import {
  useEffect, useMemo, useRef, useState,
} from "react";
import uuid from "react-uuid";
import BasicSelect from "../ui/inputs/select/BasicSelect";

type Props = {
  handleChange: (
    operator: string,
    value: any,
    type: string,
    entityType: string,
  ) => void;
  fieldSelect: boolean;
  op?: string;
  value?: any;
};

const NumberFilter = (props: Props) => {
  const {
    op,
    value,
    handleChange,
    fieldSelect,
  } = props;

  const Ops = useMemo(
    () => [
      { name: "is equal to", operator: "eq", id: 1 },
      { name: "is not equal to", operator: "ne", id: 2 },
      { name: "is greater than", operator: "gt", id: 3 },
      { name: "is less than", operator: "lt", id: 4 },
      { name: "is known", operator: "known", id: 5 },
      { name: "is unknown", operator: "unknown", id: 6 },
    ],
    [],
  );

  const [selectOp, setSelectOp] = useState<any>(Ops.find((item) => item.operator === op) || { id: -1, name: "" });
  const [selectValue, setSelectValue] = useState<any>(parseFloat(value) || 0);
  const [debouncedVal] = useDebouncedValue(selectValue, 700);
  const firstRender = useRef(true);

  useEffect(() => {
    if (!firstRender.current) {
      if (
        selectOp?.operator === "known"
      || selectOp?.operator === "unknown"
      || ((!!debouncedVal || debouncedVal === 0) && !!selectOp?.operator)
      ) {
        handleChange(
          selectOp.operator,
          parseFloat(debouncedVal),
          "int",
          "column",
        );
      }
    }
    firstRender.current = false;
  }, [debouncedVal, selectOp]);

  return (
    <>
      <BasicSelect
        disabled={fieldSelect}
        containerClass="min-w-[170px]"
        placeholder="Select Condition"
        list={Ops}
        selectedItem={selectOp}
        setSelectedItem={setSelectOp}
      />
      {selectOp?.operator !== "known" && selectOp?.operator !== "unknown" && (
        <NumberInput
          disabled={!selectOp?.operator}
          min={0}
          placeholder="Enter number"
          className="border-0 placeholder:text-gray-400"
          id={uuid()}
          value={selectValue}
          onChange={setSelectValue}
        />
      )}
    </>
  );
};

export default NumberFilter;
