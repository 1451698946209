/* eslint-disable react-hooks/exhaustive-deps */
import { NumberInput } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import {
  useEffect, useMemo, useRef, useState,
} from "react";
import uuid from "react-uuid";
import BasicSelect from "../ui/inputs/select/BasicSelect";

type Props = {
  handleChange: (
    operator: string,
    value: any,
    type: string,
    entityType: string,
    time_window: string,
  ) => void;
  fieldSelect: boolean;
  op?: string;
  value?: any;
  entity?: any;
  timeWindow: string;
};

const MetricFilter = (props: Props) => {
  const {
    op,
    value,
    handleChange,
    fieldSelect,
    entity,
    timeWindow,
  } = props;

  const Ops = useMemo(
    () => [
      {
        name: "is equal to", operator: "eq", id: 1, entityType: "changeval",
      },
      {
        name: "is not equal to", operator: "ne", id: 2, entityType: "changeval",
      },
      {
        name: "is greater than", operator: "gt", id: 3, entityType: "changeval",
      },
      {
        name: "is less than", operator: "lt", id: 4, entityType: "changeval",
      },
      {
        name: "is in range", operator: "range", id: 5, entityType: "changeval",
      },
      {
        name: "% change greater than", operator: "gt", id: 6, entityType: "growthval",
      },
      {
        name: "% change less than", operator: "lt", id: 7, entityType: "growthval",
      },
      {
        name: "% change in range", operator: "range", id: 8, entityType: "growthval",
      },
    ],
    [],
  );

  const firstRender = useRef(true);
  const [selectOp, setSelectOp] = useState<any>(Ops.find((item) => item.operator === op && item.entityType === entity) || { id: -1, name: "" });
  const [firstVal, setFirstVal] = useState<any>(value?.start ? parseFloat(value.start)
    : (parseFloat(value) || 0));
  const [debouncedVal1] = useDebouncedValue(firstVal, 700);
  const [secondVal, setSecondVal] = useState<any>(parseFloat(value?.end) || 0);
  const [debouncedVal2] = useDebouncedValue(secondVal, 700);

  useEffect(() => {
    if (!firstRender.current) {
      if (selectOp?.operator) {
        if (selectOp.operator !== "range" && (!!debouncedVal1 || debouncedVal1 === 0)) {
          handleChange(
            selectOp.operator,
            debouncedVal1,
            "int",
            selectOp.entityType,
            timeWindow,
          );
        } else if (selectOp.operator === "range" && (!!debouncedVal1 || debouncedVal1 === 0) && (!!debouncedVal2 || debouncedVal2 === 0)) {
          handleChange(
            selectOp.operator,
            {
              start: parseFloat(debouncedVal1),
              end: parseFloat(debouncedVal2),
            },
            "int",
            selectOp.entityType,
            timeWindow,
          );
        }
      }
    }
    firstRender.current = false;
  }, [debouncedVal1, debouncedVal2, selectOp, timeWindow]);

  return (
    <>
      <BasicSelect
        disabled={fieldSelect}
        containerClass="min-w-[170px]"
        placeholder="Select Condition"
        list={Ops}
        selectedItem={selectOp}
        setSelectedItem={setSelectOp}
      />
      <NumberInput
        disabled={!selectOp?.operator}
        placeholder="Enter number"
        className="border-0 placeholder:text-gray-400"
        id={uuid()}
        min={0}
        value={firstVal}
        onChange={setFirstVal}
      />
      {selectOp?.operator === "range" && (
      <NumberInput
        disabled={!selectOp?.operator}
        placeholder="Enter number"
        min={0}
        className="border-0 placeholder:text-gray-400"
        id={uuid()}
        value={secondVal}
        onChange={setSecondVal}
      />
      )}
    </>
  );
};

export default MetricFilter;
