/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Button,
  Group,
  Modal,
  Radio,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useState } from "react";
import SingleSelectDropdown from "../../../../../shared/ui/dropdown/SingleSelectDropdown";

type Props = {
  opened: boolean,
  close: any,
  shownList: any[],
  onMetricSubmit: any,
};

const NewTrendModal = ({
  opened, close, shownList, onMetricSubmit,
}: Props) => {
  const [value, setValue] = useState<any>();
  const [name, setName] = useState<any>("");
  const [check, setCheck] = useState("line");

  return (
    <Modal
      classNames={
    {
      title: "font-semibold text-[20px]",
      body: "overflow-visible",
    }
  }
      opened={opened}
      closeOnEscape
      onClose={close}
      transitionProps={{ transition: "fade", duration: 400, timingFunction: "linear" }}
      size={456}
      title={(
        <div>
          <Text>New pinned metric</Text>
          <Text size="sm" fw={400} c="text.7">Create a new metric view for quick navigation</Text>
        </div>
)}
    >
      <Stack className="mt-5" spacing={20}>
        <TextInput
          value={name}
          onChange={(event: any) => setName(event.currentTarget.value)}
          placeholder="Enterprise renewals"
          styles={(theme) => ({
            label: {
              color: theme.colors.text[4],
              fontWeight: 600,
              marginBottom: 10,
            },
          })}
          label="Metric Name"
          size="sm"
          withAsterisk
        />
        <SingleSelectDropdown
          className="w-full"
          list={[]}
          metricsList={shownList}
          selected={value}
          position="bottom"
          setSelected={setValue}
        />
        <Radio.Group
          value={check}
          onChange={setCheck}
          size="sm"
          styles={(theme) => ({
            label: {
              color: theme.colors.text[4],
              fontWeight: 600,
              marginBottom: -7,
            },
          })}
          name="favoriteFramework"
          label="Chart type"
          withAsterisk
        >
          <Group mt="xs">
            <Radio
              styles={{
                label: {
                  paddingLeft: "7px",
                },
                body: {
                  alignItems: "center",
                  justifyContent: "center",
                },
                inner: {
                  alignSelf: "center",
                },
              }}
              value="line"
              label="Line Chart"
            />
            <Radio
              styles={{
                label: {
                  paddingLeft: "7px",
                },
                body: {
                  alignItems: "center",
                  justifyContent: "center",
                },
                inner: {
                  alignSelf: "center",
                },
              }}
              value="bar"
              label="Bar Chart"
            />
          </Group>
        </Radio.Group>
      </Stack>
      <div className="flex mt-10 gap-2 justify-end items-center">
        <Button size="xs" onClick={() => { setName(null); setValue(null); setCheck("line"); close(); }} variant="default" className="text-gray-600">Cancel</Button>
        <Button
          size="xs"
          disabled={!name || !value?.data_key_slug}
          onClick={() => {
            onMetricSubmit(value?.data_key_slug, name, check);
            setName(null); setValue(null);
          }}
          className="bg-purple-600"
        >
          Create Metric

        </Button>
      </div>
    </Modal>
  );
};

export default NewTrendModal;
