/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { Box, Tooltip } from "@mantine/core";
import { ITableProps, Table, kaReducer } from "ka-table";
import {
  updatePageIndex,
} from "ka-table/actionCreators";
import {
  ActionType, DataType,
  SortDirection, SortingMode,
} from "ka-table/enums";
import { Column } from "ka-table/models";
import { IPagingProps } from "ka-table/props";
import "ka-table/style.css";
import { DispatchFunc } from "ka-table/types";
import {
  forwardRef,
  useMemo,
} from "react";
import ReactPaginate from "react-paginate";
import { ReactComponent as Sort } from "../../assets/images/sort.svg";
import AutomationTimestampCell from "./AutomationTimestampCell";
import AvatarCell from "./AvatarCell";
import DataCell from "./DataCell";
import DateCell from "./DateCell";
import DeleteSignalCell from "./DeleteSignalCell";
import DropdownCell from "./DropdownCell";
import EmptyTablePlaceholder from "./EmptyTablePlaceHolder";
import HeadCell from "./HeadCell";
import IconsGroupCell from "./IconsGroupCell";
import LastTimeEntry from "./LastTimeEntry";
import LocationCell from "./LocationCell";
import MemberCell from "./MemberCell";
import MetricCell from "./MetricCell";
import PercentCell from "./PercentCell";
import PlaybookActionsCell from "./PlaybookActionsCell";
import RelatedAccountsCell from "./RelatedAccountsCell";
import SignalActivityCell from "./SignalActivityCell";
import SignalNameCell from "./SignalNameCell";
import SourceCell from "./SourceCell";
import StatusCell from "./StatusCell";
import TopFeatureCell from "./TopFeatureCell";

export interface DataTable {

  totalData?: number,

  relation?: string,

  /**
   * Specifies the key of the column that should be used as the row key.
   */
  rowKeyField: string

  /**
   * Specifies if the column resizing should be enabled.
   */
  columnResizing?: boolean

  pageRange?: number

  /**
   * Height of the table.
   */
  height?: number

  /**
   * Specifies the initial sort direction.
   */

  /**
   * Specifies the callback to be called when the table is scrolled to the bottom
   *  for loading more data.
   * @param pageIndex - The current page index
   */
  serverCallback?: (
    pageIndex: number,
    sortBy: {
      key: string
      direction: SortDirection
    }
  ) => Promise<any>

  /**
   * Specifies the callback to be called when a row is clicked.
   */
  onRowClick?: (row: any) => void

  itemType?: string

  /**
   * Optional setter for the table data.
   */
  setData?: (data: any[]) => void

  /**
   * Callback to be called when no data is available.
   */
  noDataAvailable?: () => void

  /**
   * Callback to be called when column resize or reordered
   */
  onColumnChange?: (payload: IActionPayload) => void

  /**
   * Callback to be called when column resize or reordered
   */
  onSortChange?: (payload: { field: string, order: string, type?: string }) => void

  onPageChange?: (payload: number) => void

  tableProps: ITableProps,

  changeTableProps: any,

  kaDispatch: any,

  noDataMessage?: string,

  noDataTitle?: string
}

export interface IActionPayload {
  type: ActionType.ResizeColumn | ActionType.ReorderColumns
  columnKey: string
  targetColumnKey?: string
  width?: number
}

export interface ISortBy {
  key: string
  direction: SortDirection
  type?: string
}

export interface IColumn extends Column {
  key: string
  children?: IColumn[]
  displayName?: string
  parentKey?: string
  sortType?: string
  attributeType?: string
}

const KaTable = forwardRef(
  (
    {
      tableProps,
      changeTableProps,
      kaDispatch,
      itemType = "Items",
      totalData = 0,
      onColumnChange = () => {},
      onPageChange = () => {},
      onSortChange = () => {},
      onRowClick = () => {},
      pageRange = 5,
      noDataMessage,
      noDataTitle,
    }: DataTable,
    ref,
  ) => {
    const dispatch: DispatchFunc = (action) => {
      changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
      const sortColumn: any = tableProps.columns.find((c: any) => c.sortDirection);
      if (action.type === ActionType.ResizeColumn || action.type === ActionType.ReorderColumns) {
        if (action.type === ActionType.ResizeColumn) {
          onColumnChange({
            type: action.type,
            columnKey: action.columnKey,
            width: action.width,
          });
        } else {
          onColumnChange({
            type: action.type,
            columnKey: action.columnKey,
            targetColumnKey: action.targetColumnKey,
          });
        }
      }

      if (action.type === ActionType.UpdatePageIndex) {
        // change the page index
        onPageChange(action.pageIndex);
      }

      if (action.type === ActionType.UpdateSortDirection) {
        // change sort direction
        const newSortDirection = sortColumn?.sortDirection === SortDirection.Ascend
          ? SortDirection.Descend : SortDirection.Ascend;

        if (action.sortingMode === SortingMode.SingleRemote) {
          onSortChange({
            field: action.columnKey,
            order: newSortDirection === SortDirection.Ascend ? "asc" : "desc",
            type: sortColumn?.type || undefined,
          });
        }
      }
    };

    const ofs = useMemo(() => (
      (
        (((tableProps?.paging?.pageIndex ?? 0) * (tableProps?.paging?.pageSize ?? 1))
      % (totalData ?? 1))) + 1
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [tableProps?.paging?.pageIndex, tableProps?.paging?.pageSize, totalData]);

    return (
      <Box
        sx={(theme: any) => ({
          ".ka": {
            background: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
          },
          ".ka-dragged-row": {
            opacity: "0.5",
          },
          ".ka-drag-over-row": {
            boxShadow: "inset 0 7px 0px -4px red",
          },
          ".ka-dragged-row ~ .ka-drag-over-row": {
            boxShadow: "inset 0 -7px 0px -4px red",
          },
          ".ka-drag-over-column": {
            boxShadow: "inset 7px 0 0px -4px red",
          },
          ".ka-dragged-column ~ .ka-drag-over-column": {
            boxShadow: "inset -7px 0 0px -4px red",
          },
        })}
      >
        <Table
          {...tableProps}
          dispatch={kaDispatch || dispatch}
          childComponents={{
            sortIcon: {
              content: ({ column }: any) => (
                <Tooltip withinPortal classNames={{ tooltip: "transform-none text-xs" }} label={`Sort ${column?.sortDirection === SortDirection.Ascend ? "Desc" : "Asc"}`}>
                  <Sort
                    className={`${column.sortDirection === SortDirection.Ascend ? "scale-y-[1]" : "scale-y-[-1]"} active:text-gray-900 shrink-0 cursor-pointer hover:text-gray-900`}
                  />
                </Tooltip>
              ),
            },
            headRow: {
              elementAttributes: () => ({
                style: {
                  width: "100%",
                },
                className: "!bg-gray-100 !border-gray-200",
              }),
            },
            headCell: {
              content: (props) => <HeadCell {...props} />,
              elementAttributes: () => ({
                style: {
                  zIndex: 1,
                  width: "100%",
                },
                className: "!bg-gray-100 min-w-[100px] group hover:!bg-gray-300 !ease-linear !duration-200 !py-2 !pl-6 first:sticky left-0 first:!z-[2]",
              }),
            },
            noDataCell: {
              content: () => <EmptyTablePlaceholder title={noDataTitle} message={noDataMessage} />,
            },
            headCellResize: {
              elementAttributes: () => ({
                style: {
                  zIndex: 5,
                },
                className: "!bg-gray-300 !w-[3px] cursor-col-resize group-hover:!bg-gray-400 self-center hover:bg-gray-500",
              }),
            },
            dataRow: {
              elementAttributes: () => ({
                onClick: (_event: any, extendedEvent: { childProps: { rowData: any; }; }) => {
                  onRowClick(extendedEvent.childProps.rowData);
                },
                className: "[&:hover>*]:bg-gray-100 !ease-linear !duration-300",
              }),
            },
            paging: {
              content: (props: IPagingProps) => (
                <div className="flex w-full border-t-4 z-[10] justify-between bg-white items-center">
                  <ReactPaginate
                    previousLabel="← Previous"
                    nextLabel="Next →"
                    breakLabel="..."
                    marginPagesDisplayed={1}
                    forcePage={props?.pageIndex ?? 0}
                    pageCount={props?.pagesCount ?? 1}
                    onPageChange={async (selected: any) => {
                      await props.dispatch(
                        updatePageIndex(selected.selected),
                      );
                    }}
                    pageRangeDisplayed={pageRange}
                    containerClassName="flex w-full p-5 gap-4 list-none "
                    pageLinkClassName="cursor-pointer p-[7px] rounded-lg text-sm border-purple-600 border-1 text-purple-600"
                    previousLinkClassName="text-purple-600 font-bold text-xs"
                    nextLinkClassName="text-purple-600 font-bold text-xs"
                    disabledLinkClassName="!text-gray-300 border-gray-50 cursor-default"
                    activeLinkClassName="!text-gray-100 bg-purple-500 cursor-pointer p-[7px]"
                    renderOnZeroPageCount={null}
                  />
                  {(props?.pagesCount ?? 0) > 0 && (
                  <span className="text-xs italic mr-5 whitespace-nowrap text-gray-600">
                    {ofs || 0}
                    -
                    {ofs + ((props?.pageSize || 1) - 1) < totalData
                      ? ofs + ((props?.pageSize || 1) - 1)
                      : totalData}
                    {" "}
                    of
                    {" "}
                    {`${totalData} ${itemType}`}
                  </span>
                  )}
                </div>
              ),
            },
            cell: {
              elementAttributes: () => ({
                style: {
                  overflow: "hidden",
                  verticalAlign: "middle",
                },
                className: " bg-white text-sm text-gray-900 ease-linear duration-200 first:sticky left-0",
              }),
              // eslint-disable-next-line consistent-return
              content: (props: any) => {
                const value = props.rowData[`${props?.column?.key}`];
                if (props.column.dataType === "delete_signal") {
                  return <DeleteSignalCell {...props} />;
                }
                if (props.column.dataType === "playbook_actions") {
                  return <PlaybookActionsCell {...props} />;
                }
                if (value === "null" || value === null || value === undefined) {
                  return <span className="block h-[5px] bg-gray-200 w-[40px] rounded-lg" />;
                }
                if (props.column.key === "related_accounts") {
                  return <RelatedAccountsCell {...props} />;
                }
                if (props.column.dataType === "signal_name") {
                  return <SignalNameCell {...props} />;
                }
                if (props.column.dataType === "signal_status") {
                  return <SignalActivityCell {...props} />;
                }
                if (props.column.dataType === "last_activity") {
                  return <LastTimeEntry {...props} />;
                } if (props.column.dataType === "top_feature") {
                  return <TopFeatureCell {...props} />;
                }
                if (props.column.key === "send_via") {
                  return <IconsGroupCell {...props} />;
                }
                if (props.column.dataType === "automation_timestamp") {
                  return <AutomationTimestampCell {...props} />;
                }
                if (props.column.key === "status") {
                  return <StatusCell {...props} />;
                }
                if (props.column.key.includes("retention") || props.column.key.includes("adoption")) {
                  return <PercentCell {...props} />;
                }
                if (props.column?.attributeType) {
                  if (props.column?.attributeType === "metric_traits") {
                    return <MetricCell {...props} />;
                  }
                }
                if (props.column.key === "accounts.csm_id" || props.column.key === "accounts.ae_id"
                || props.column.key === "accounts.ae_updated_by_user_id" || props.column.key === "accounts.csm_updated_by_user_id") {
                  return <MemberCell {...props} />;
                }
                if (props.column.key === "users.name" || props.column.key === "accounts.name") {
                  return <AvatarCell {...props} />;
                }
                if (props.column.key === "location") {
                  return <LocationCell {...props} />;
                }
                if (props.column.key.includes("source") || props.column.key === "src") {
                  return <SourceCell {...props} />;
                }
                if (props.column?.dataType === DataType.Object) {
                  return <DropdownCell {...props} />;
                }
                if (typeof value === "string" && value?.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}$/)) {
                  return <DateCell {...props} />;
                }
                return <DataCell {...props} />;
              },
            },
          }}
        />
      </Box>
    );
  },
);

export default KaTable;
