import { QueryClient, UseQueryOptions, useQuery } from "@tanstack/react-query";
import { getApiKey } from "../services/apiKeyEndpoint";
import { readAllAutomations, readAutomationLogs } from "../services/automation/automationEndpoints";
import { getAccountById } from "../services/companyEndpoint";
import { readAllMetrics } from "../services/metric/metricEndpoint";
import { readAllPlaybooks } from "../services/playbook/playbookEndpoints";
import { readWorkspacePreference } from "../services/preference/preferenceEndpoints";
import { getContactById, getContactsByAccountId } from "../services/userContactsEndpoint";
import { currentUser } from "../services/usersEndpoint";
import { getViews } from "../services/view/viewEndpoints";
import { getWorkspace } from "../services/workspace/workspaceEndpoints";
import { allWorkspaceMembers } from "../services/workspace-members/workspaceMemberEndpoint";
import arrayToObject from "../utils/helpers";
import notify from "../utils/notify";
import { readSignalRuns } from "../services/inbox/inboxEndpoints";
import {
  getAllIntegration, getIntegrationEvents, getSlackChannels, getSlackIntegration, getSlackUsers,
} from "../services/integrationEndpoint";
import { WorkspaceMemberResponse } from "../services/workspace-members/workspaceMembers.interface";
import { GetTraitsAndMetricsResponse, Metrics, Traits } from "../services/data-index/dataIndexEndpoints.interface";
import { getTraitsAndMetrics } from "../services/data-index/dataIndexEndpoints";
import { View, ViewsResponse } from "../services/view/viewEndpoints.interface";

export const queryClient = new QueryClient();

export const useCurrentUser = ({ ...queryOptions }: UseQueryOptions) => useQuery({
  queryKey: ["user"],
  queryFn: async () => {
    const response = await currentUser();
    if (!(response.status === 200)) {
      notify({
        heading: "Error",
        messageList: ["Failed to fetch user, kindly try again"],
        type: "failure",
      });
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
    }
    return response.data;
  },
  staleTime: 1000 * 60 * 60 * 24,
  ...queryOptions,
});

export const useApiKey = ({ workspaceId, ...queryOptions }:
{ workspaceId?: string } & UseQueryOptions) => useQuery({
  queryKey: ["apiKey", workspaceId],
  queryFn: () => getApiKey({
    workspace_id: workspaceId,
  }),
  select: (data: any) => data?.data,
  staleTime: 1000 * 60 * 60 * 24,
  ...queryOptions,
});

export const useGetEvents = ({
  workspaceId, integration_name, token, ...queryOptions
}:
{ workspaceId: string, integration_name: string, token: string } & UseQueryOptions) => useQuery({
  queryKey: ["getEvents", workspaceId, integration_name, token],
  queryFn: () => getIntegrationEvents({
    workspace_id: workspaceId,
    integration_name,
    token,
  }),
  select: (ev: any) => {
    const events = ev?.data?.events;
    const data: any = {};
    events.forEach((event: any) => {
      if (data[event.event_type]) {
        data[event.event_type].push({ ...event });
      } else {
        data[event.event_type] = [{ ...event }];
      }
    });
    return data;
  },
  ...queryOptions,
});

export const useCurrentWorkspace = ({ workspaceId, ...queryOptions }:
{ workspaceId: string } & UseQueryOptions) => useQuery({
  queryKey: ["workspace", workspaceId],
  queryFn: () => getWorkspace({
    workspace_id: workspaceId,
  }),
  select: (data: any) => data?.data,
  staleTime: 1000 * 60 * 60 * 24,
  ...queryOptions,
});

export const useWorkspacePreferences = ({ workspaceId, ...queryOptions }:
{ workspaceId: string } & UseQueryOptions) => useQuery({
  queryKey: ["workspace_preferences", workspaceId],
  queryFn: () => readWorkspacePreference({
    workspace_id: workspaceId,
  }),
  select: (data: any) => data?.data,
  staleTime: 1000 * 60 * 30,
  ...queryOptions,
});

export const useWorkspaceMetricPreferences = ({ workspaceId, ...queryOptions }:
{ workspaceId: string } & UseQueryOptions) => useQuery({
  queryKey: ["workspace_metric_preferences", workspaceId],
  queryFn: () => readAllMetrics({
    workspace_id: workspaceId,
  }),
  select: (data: any) => data?.data,
  staleTime: 1000 * 60 * 30,
  ...queryOptions,
});

export const useWorkspaceMembers = ({
  workspaceId,
  ...queryOptions
}: { workspaceId: string }
& UseQueryOptions<WorkspaceMemberResponse, Error>) => useQuery<WorkspaceMemberResponse, Error>({
  queryKey: ["workspace_members", workspaceId],
  queryFn: () => allWorkspaceMembers({ workspace_id: workspaceId }).then((res) => res.data),
  ...queryOptions,
});

export const useViews = ({ workspaceId, ...queryOptions }:
{ workspaceId: string } &
UseQueryOptions<ViewsResponse, Error, View[]>) => useQuery<ViewsResponse, Error, View[]>({
  queryKey: ["views", workspaceId],
  queryFn: () => getViews({
    workspace_identifier: workspaceId,
  }).then((res) => res.data),
  select: (data) => data?.views,
  staleTime: 1000 * 60 * 60 * 24,
  ...queryOptions,
});

export const useTraitsAndMetrics = ({ workspaceId, ...queryOptions }:
{ workspaceId: string } &
UseQueryOptions<GetTraitsAndMetricsResponse,
Error, {
  accountMetrics: { list: Metrics[]; obj: {
    [key: string]: Metrics;
  } };
  accountTraits: { list:Traits[]; obj: {
    [key: string]: Traits;
  }
  };
  contactMetrics: { list: Metrics[]; obj: {
    [key: string]: Metrics;
  } };
  contactTraits: { list: Traits[]; obj: {
    [key: string]: Traits;
  }
  };
}>) => useQuery<GetTraitsAndMetricsResponse, Error, {
  accountMetrics: { list: Metrics[]; obj: {
    [key: string]: Metrics;
  } };
  accountTraits: { list:Traits[]; obj: {
    [key: string]: Traits;
  }
  };
  contactMetrics: { list: Metrics[]; obj: {
    [key: string]: Metrics;
  } };
  contactTraits: { list: Traits[]; obj: {
    [key: string]: Traits;
  }
  };
}>({
  queryKey: ["traits_metrics", workspaceId],
  queryFn: () => getTraitsAndMetrics({ workspace_id: workspaceId }).then((res) => res.data),
  select: (response) => {
    const { object_definitions: dataIndices } = response;
    // Filter and map the data to the correct categories and types
    const accountTraits: Traits[] = dataIndices.find((data) => data.slug_name === "accounts")!.data_indices;
    const contactTraits: Traits[] = dataIndices.find((data) => data.slug_name === "users")!.data_indices;
    const accountMetrics = response.account_metrics;
    const contactMetrics = response.contact_metrics;
    return {
      accountMetrics: {
        list: accountMetrics,
        obj: arrayToObject(accountMetrics, "data_key_slug"),
      },
      accountTraits: {
        list: accountTraits,
        obj: arrayToObject(accountTraits, "data_key_slug", "accounts"),
      },
      contactMetrics: {
        list: contactMetrics,
        obj: arrayToObject(contactMetrics, "data_key_slug"),
      },
      contactTraits: {
        list: contactTraits,
        obj: arrayToObject(contactTraits, "data_key_slug", "users"),
      },
    };
  },
  staleTime: 1000 * 60 * 60 * 24, // Keeping the same caching strategy
  ...queryOptions,
});

export const useAccount = ({ workspaceId, accountId, ...queryOptions }:
{ workspaceId: string, accountId: string } & UseQueryOptions) => useQuery({
  queryKey: ["account", workspaceId, accountId],
  queryFn: () => getAccountById(
    { workspace_identifier: workspaceId, external_id: accountId },
  ),
  select: (data: any) => data?.data,
  staleTime: 1000 * 60 * 10,
  ...queryOptions,
});

export const useContact = ({ workspaceId, contactId, ...queryOptions }:
{ workspaceId: string, contactId: string } & UseQueryOptions) => useQuery({
  queryKey: ["contact", workspaceId, contactId],
  queryFn: () => getContactById(
    { workspace_identifier: workspaceId, external_id: contactId },
  ),
  select: (data: any) => data?.data,
  staleTime: 1000 * 60 * 10,
  ...queryOptions,
});

export const useAccountContacts = ({ workspaceId, accountId, ...queryOptions }:
{ workspaceId: string, accountId: string } & UseQueryOptions) => useQuery({
  queryKey: ["account_contacts", workspaceId, accountId],
  queryFn: () => getContactsByAccountId(
    { workspace_identifier: workspaceId, company_id: accountId },
  ),
  select: (data: any) => data?.data,
  staleTime: 1000 * 60 * 10,
  ...queryOptions,
});

export const useAllAutomations = ({ workspaceId, automationType, ...queryOptions }:
{ workspaceId: string, automationType: string } & UseQueryOptions) => useQuery({
  queryKey: ["all_automations", workspaceId, automationType],
  queryFn: () => readAllAutomations({ workspace_id: workspaceId, automation_type: automationType }),
  select: (data: any) => data?.data,
  staleTime: 1000 * 60 * 10,
  ...queryOptions,
});

export const useAllPlaybooks = ({ workspaceId, ...queryOptions }:
{ workspaceId: string } & UseQueryOptions) => useQuery({
  queryKey: ["all_playbooks", workspaceId],
  queryFn: () => readAllPlaybooks({ workspace_id: workspaceId }),
  select: (data: any) => data?.data,
  staleTime: 1000 * 60 * 10,
  ...queryOptions,
});

export const useAutomationLogs = ({
  workspaceId, automationId, page = 0, status, automationType, ...queryOptions
}:
{ workspaceId: string,
  automationType?: string,
  automationId?: string,
  status?:string,
  page?:number } & UseQueryOptions) => useQuery({
  queryKey: ["automation_logs", workspaceId, automationId, automationType, status, page],
  queryFn: () => (readAutomationLogs({
    workspace_id: workspaceId,
    automation_id: automationId || undefined,
    automation_type: automationType,
    status: status || undefined,
    skip: page * 20,
    limit: 20,
  })),
  select: (data: any) => data?.data,
  staleTime: 1000 * 60 * 10,
  ...queryOptions,
});

export const useInbox = ({
  workspaceId,
  automationId,
  timeStart,
  timeEnd,
  playbookId,
  signalStatus,
  notificationType,
  entityRelation,
  owner,
  ...queryOptions
}:
{ workspaceId: string,
  timeStart?: string,
  timeEnd?: string,
  playbookId?:string,
  automationId?: string,
  signalStatus?: "no_status" | "starred" | "dismissed" | "Done",
  notificationType: "inbox" | "notification",
  entityRelation?: "accounts" | "users",
  owner?: string[],
} & UseQueryOptions) => useQuery({
  queryKey: ["inbox",
    workspaceId,
    automationId,
    entityRelation,
    timeStart,
    timeEnd,
    playbookId,
    signalStatus,
    notificationType,
    owner],
  queryFn: () => (readSignalRuns({
    workspace_id: workspaceId,
    entity_relation: entityRelation,
    automation_id: automationId || undefined,
    time_start: timeStart,
    time_end: timeEnd,
    playbook_id: playbookId || undefined,
    signal_status: signalStatus || undefined,
    notification_type: notificationType,
    owner_id: owner || undefined,
  })),
  select: (data: any) => data?.data?.results,
  staleTime: 1000 * 60 * 10,
  retryOnMount: false,
  ...queryOptions,
});

export const useAllIntegrations = ({ workspaceId, ...queryOptions }:
{ workspaceId: string } & UseQueryOptions) => useQuery({
  queryKey: ["integrations", workspaceId],
  queryFn: () => getAllIntegration({ workspace_id: workspaceId }),
  select: (data: any) => data?.data,
  staleTime: 1000 * 60 * 10,
  ...queryOptions,
});

export const useSlackIntegration = ({ workspaceId, ...queryOptions }:
{ workspaceId: string } & UseQueryOptions) => useQuery({
  queryKey: ["slack-integration", workspaceId],
  queryFn: () => getSlackIntegration({ workspace_id: workspaceId }),
  select: (data: any) => data?.data,
  staleTime: 1000 * 60 * 10,
  retry: 0,
  ...queryOptions,
});

export const useSlackUsers = ({ workspaceId, ...queryOptions }:
{ workspaceId: string } & UseQueryOptions) => useQuery({
  queryKey: ["slack-users", workspaceId],
  queryFn: () => getSlackUsers({ workspace_id: workspaceId }),
  select: (data: any) => data?.data,
  staleTime: 1000 * 60 * 10,
  ...queryOptions,
});

export const useSlackChannels = ({ workspaceId, ...queryOptions }:
{ workspaceId: string } & UseQueryOptions) => useQuery({
  queryKey: ["slack-channels", workspaceId],
  queryFn: () => getSlackChannels({ workspace_id: workspaceId }),
  select: (data: any) => data?.data,
  staleTime: 1000 * 60 * 10,
  ...queryOptions,
});
