/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import {
  ActionIcon, Collapse, Flex, Paper, Text, Tooltip,
} from "@mantine/core";
import { ITableProps, kaReducer } from "ka-table";
import {
  hideLoading, loadData, updateData,
} from "ka-table/actionCreators";
import { SortDirection, SortingMode } from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";
import {
  useEffect,
  useRef,
  useState,
} from "react";
import { IconInfoCircle } from "@tabler/icons-react";
import { getTopNFeatures } from "../../../../../services/companyEndpoint";
import EmptyTablePlaceholder from "../../../../../shared/table/EmptyTablePlaceHolder";
import KaTable from "../../../../../shared/table/KaTable";
import useWorkspaceStore from "../../../../../store/workspace/workspaceStore";
import parseTableData from "../../../../../utils/parseTable";

type Props = {
  accountId: string,
  tableHeight: number,
  containerClass?: string,
  headingClass?: string,
  defaultOpen?: boolean,
};

const TopN = ({
  accountId, tableHeight, containerClass, headingClass,
  defaultOpen = true,
}: Props) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const workspaceId = useWorkspaceStore((state) => state.workspace?.id);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const tableRef = useRef<any>(null);

  const [tableProps, changeTableProps] = useState<any>({
    columns: [
      {
        key: "feature",
        dataType: "top_feature",
        title: "Feature",
        isResizable: true,
        colGroup: { style: { width: 180, minWidth: 100 } },
        attributeType: "object_traits",
        isSortable: false,
      }, {
        key: "adoption",
        title: "Adoption",
        dataType: "string",
        isResizable: true,
        colGroup: { style: { minWidth: 50 } },
        attributeType: "object_traits",
        isSortable: false,
      }, {
        key: "retention",
        title: "Retention",
        dataType: "string",
        isResizable: true,
        colGroup: { style: { minWidth: 50 } },
        sortDirection: SortDirection.Descend,
        attributeType: "object_traits",
        isSortable: false,
      },
    ],
    columnResizing: true,
    rowKeyField: "randomId",
    height: tableHeight,
    singleAction: loadData(),
    sortingMode: SortingMode.SingleRemote,
    loading: {
      enabled: true,
      text: "Loading",
    },
  });

  const kaDispatch: DispatchFunc = (action: any) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
  };

  const fetchData = async () => {
    try {
      const response: any = await getTopNFeatures({
        workspace_identifier: workspaceId,
        account_id: accountId,
      });

      if (response?.data?.results?.length) {
        setNoResults(false);
        setTotal(response.data.results.length);
        const list = parseTableData({ data: response.data.results });
        kaDispatch(updateData(list));
        kaDispatch(hideLoading());
      } else {
        setTotal(0);
        setNoResults(true);
        kaDispatch(updateData([]));
        kaDispatch(hideLoading());
      }
    } catch (err) {
      setTotal(0);
      setNoResults(true);
      kaDispatch(updateData([]));
      kaDispatch(hideLoading());
    }
  };

  useEffect(() => {
    fetchData();
  }, [tableProps?.paging?.pageIndex, tableProps.columns, accountId]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Paper shadow="md" radius="md" className={`border-2 border-gray-200 ${containerClass || ""}`}>
      <Flex align="center" p="sm" className="border-b-2" justify="space-between">
        <Flex gap={8} align="center">
          <Text size={20} c="text.9" fw={600} className={headingClass}>
            Top 5 features
          </Text>
          <Tooltip multiline width={250} classNames={{ tooltip: "text-xs bg-gray-700 bg-opacity-80" }} label="See how your features are performing and how they compare to others">
            <IconInfoCircle className=" text-gray-500 hover:cursor-none h-4 w-4 hover:text-gray-800" />
          </Tooltip>
        </Flex>
        <ActionIcon onClick={handleToggle} color="dark" size="xs">
          <ChevronUpIcon style={{ transform: isOpen ? "rotate(0)" : "rotate(180deg)" }} />
        </ActionIcon>
      </Flex>
      <Collapse in={isOpen}>
        {noResults ? (<EmptyTablePlaceholder height={tableHeight} />)
          : (
            <KaTable
              tableProps={tableProps}
              totalData={total}
              kaDispatch={kaDispatch}
              rowKeyField="randomId"
              changeTableProps={changeTableProps}
              ref={tableRef}
              noDataTitle="No Features Available"
              itemType="Users"
            />
          )}
      </Collapse>
    </Paper>
  );
};

export default TopN;
