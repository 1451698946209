/* eslint-disable react-hooks/exhaustive-deps */
import { FunnelIcon } from "@heroicons/react/24/outline";
import {
  Container, Flex, Select, Skeleton, Text,
} from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";
import { useViews } from "../../../../hooks/QueryHooks";
import { getWorkspaceTrendsAgaintQuery } from "../../../../services/workspace/workspaceEndpoints";
import SelectItemWithTooltip from "../../../../shared/select/SelectItemWIthTooltip";
import AvatarWithMrr from "./AvatarWithMrr";

type Props = {
  workspace: any,
  onAccountClick: any,
  selectedAccId?: string | null,
};

const MyAccounts = ({ workspace, onAccountClick, selectedAccId }: Props) => {
  const { data: views }: any = useViews({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  const params = useParams();

  const selectOptions = useMemo(() => views?.reduce((arr: any[], view:any) => {
    if (view?.entity_relation === "accounts") {
      arr.push({ value: view.id, label: view.view_name });
    }
    return arr;
  }, []) || [], [views]);
  const [selectedView, setSelectedView] = useLocalStorageState<any>("selected_accounts_view", { defaultValue: selectOptions?.[0]?.value });
  const [accounts, setAccounts] = useState<any>([]);
  const currentView = useMemo(() => views?.find(
    (view:any) => view.id === selectedView,
  ), [selectedView, views]);

  useEffect(
    () => {
      if (!selectedView && selectOptions?.length) {
        setSelectedView(selectOptions[0]?.value);
      }
    },
    [selectOptions, selectedView],
  );

  useEffect(() => {
    const getData = async () => {
      if (workspace?.id && views?.length && currentView?.id) {
        try {
          const response: any = await getWorkspaceTrendsAgaintQuery(
            {
              workspaceId: workspace?.id,
              entityType: currentView?.entity_relation,
              query: {
                include_count: true,
                page: 1,
                per_page: 5,
                filter: currentView?.filters,
                selected_fields: [
                  {
                    name: "accounts.name",
                    data_type: "Text",
                    field_type: "default",
                  },
                  {
                    name: "accounts.external_id",
                    data_type: "Text",
                    field_type: "default",
                  },
                  {
                    name: "accounts.avatar_url",
                    data_type: "Text",
                    field_type: "default",
                  },
                  {
                    name: "accounts.mrr",
                    data_type: "Integer",
                    field_type: "default",
                  },
                  {
                    name: "accounts.last_seen",
                    data_type: "Date",
                    field_type: "default",
                  },
                ],
                sort: currentView?.sort_fields,
                metrics: currentView?.metrics,
                time_window: currentView?.metrics_time_frame,
                time_zone: workspace?.workspace_time_zone,
              },
            },
          );
          if (response?.data) {
            setAccounts(response.data);
            if (selectedAccId) {
              onAccountClick(selectedAccId, true);
            }
          }
        } catch (err) {
          setAccounts([]);
        }
      }
    };
    getData();
  }, [workspace?.id, selectedView, views]);

  return (
    <Container fluid p={16} bg="white" className="rounded-md shadow-sm">
      <Flex align="center" justify="space-between">
        <Text color="text.9" size={20} fw={600}>Accounts</Text>
        <Select
          value={selectedView}
          onChange={(value) => setSelectedView(value)}
          data={selectOptions}
          itemComponent={SelectItemWithTooltip}
          classNames={{
            input: "text-gray-500 hover:border-purple-500 peer focus:border-purple-600 hover:text-purple-600 focus:text-purple-700 overflow-hidden text-ellipsis pr-5",
          }}
          color="main.5"
          size="sm"
          icon={<FunnelIcon className="w-5 h-5 peer-hover:text-purple-500 peer-focus:text-purple-600" />}
        />
      </Flex>
      <Text size="xs" mt={10} mb={5} transform="uppercase" lts={0.6} c="text.4">
        {currentView?.view_name}
      </Text>
      <Flex align="center" justify="start" wrap="wrap" gap={20}>
        {
        accounts?.length ? accounts.map((item: any) => (
          <AvatarWithMrr
            selected={selectedAccId === item["accounts.external_id"]}
            id={item["accounts.external_id"]}
            key={item["accounts.external_id"]}
            onClick={onAccountClick}
            name={item?.["accounts.name"]}
            img={item?.["accounts.avatar_url"]}
            mrr={item?.["accounts.mrr"]}
          />
        )) : <Skeleton />
      }
      </Flex>
      <div className="mt-3">
        <Link
          className="text-sm text-purple-700 mt-1 hover:text-purple-900 font-semibold"
          to={`/home/${params?.workspaceId}/view/${selectedView}`}
        >
          View all
        </Link>
      </div>
    </Container>
  );
};

export default MyAccounts;
