import { Fragment } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Listbox, Transition } from "@headlessui/react";

function classNames(...classes:string[]) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  list: { id: number | string, name: string, label?: string }[],
  selectedItem: { id: number | string, name: string, label?: string, [key: string]: any },
  setSelectedItem: any,
  label?:string, containerClass?: string,
  className? : string,
  placeholder?: string,
  maxHeight?:string, error?: boolean,
  textClass?:string,
  hint?:string,
  disabled?:boolean,
  LeadingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>,
};

const BasicSelect = ({
  list,
  disabled = false,
  className,
  textClass,
  selectedItem,
  setSelectedItem,
  label,
  containerClass,
  placeholder,
  maxHeight,
  error = false,
  hint,
  LeadingIcon,
} : Props) => (
  <Listbox disabled={disabled} value={selectedItem} onChange={setSelectedItem}>
    {({ open }) => (
      <div className={`${containerClass || ""} relative`}>
        {label && <Listbox.Label className="block mb-1 text-sm font-medium text-gray-500">{label}</Listbox.Label>}
        <Listbox.Button className={`relative w-full ${error ? "border-red-300 hover:border-red-400 focus:ring-red-400 focus:border-red-400 shadow-red-300" : "border-gray-300 hover:border-secondary-400 focus:ring-primary-400 focus:border-primary-400"} focus:ring-1 focus:shadow-textBox cursor-pointer rounded-md border bg-white py-2 pl-3 pr-10 text-left shadow-sm text-sm disabled:bg-gray-200 disabled:text-gray-200 disabled:border-none disabled:cursor-default ${className}'`}>
          {LeadingIcon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <LeadingIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          )}
          <span className={`block ${LeadingIcon && "pl-7"} truncate ${(selectedItem?.id === -1) && "text-gray-400"} ${error && "text-red-500 "} ${disabled && "text-gray-200"} ${textClass}`}>{selectedItem?.id !== -1 ? (selectedItem?.label || selectedItem?.name) : placeholder}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          show={open}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className={`absolute z-10 mt-1 max-h-${maxHeight || "60"} w-full capitalize overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}>
            {list.map((item) => (
              <Listbox.Option
                key={item.id}
                className={({ active }) => classNames(
                  active ? "bg-purple-600 text-white" : "text-gray-900",
                  "relative cursor-default select-none py-2 pl-3 pr-9",
                  item.id === -1 && !active ? "text-[#6B7280] " : "",
                )}
                value={item}
              >
                {({ selected, active }) => (
                  <>
                    <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
                      {item?.label ? item.label : item.name}
                    </span>
                    {selected && item.id !== -1 ? (
                      <span
                        className={classNames(
                          active ? "text-white" : "text-purple-600",
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
        {hint && (
        <p className={`mt-2 text-xs font-normal ${error ? "text-red-500" : "text-gray-500"}`}>
          {hint}
        </p>
        )}
      </div>
    )}
  </Listbox>
);

export default BasicSelect;
