/* eslint-disable @typescript-eslint/no-unsafe-return */
import { create } from "zustand";

type UsersTableStore = {
  usersTable: any;
  updateUsersTable: (usersTable: any) => void;
};

const useUsersTableStore = create<UsersTableStore>((set) => ({
  // state data and actions will go here
  usersTable: {
    workspace_identifier: "",
    include_count: true,
    page: 1,
    per_page: 10,
    filter: {},
    selected_fields: [
      {
        name: "users.name",
        data_type: "Text",
        field_type: "default",
      },
      {
        name: "users.email",
        data_type: "Text",
        field_type: "default",
      },
      {
        name: "users.last_seen",
        data_type: "Date",
        field_type: "default",
      },
      {
        name: "users.avatar_url",
        data_type: "Text",
        field_type: "default",
      }, {
        name: "users.external_id",
        data_type: "Text",
        field_type: "default",
      }, {
        name: "users.record_created_at",
        data_type: "Date",
        field_type: "default",
      }],
    metrics: [],
    sort: {
      field: "users.last_seen",
      order: "desc",
      type: "property",
      data_type: "Date",
      field_type: "default",
    },
  } as any,
  updateUsersTable: (usersTable: any) => set(
    (state: any) => ({
      usersTable:
    { ...state.usersTable, ...usersTable },
    }),
  ),
}));

export default useUsersTableStore;
