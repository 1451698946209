/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable react-hooks/exhaustive-deps */
import { CubeTransparentIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { NewspaperIcon, PresentationChartBarIcon } from "@heroicons/react/24/outline";
import {
  Menu,
  Paper, Text,
} from "@mantine/core";
import React, {
  useEffect,
  useMemo, useRef, useState,
} from "react";
import uuid from "react-uuid";
import { ReactComponent as He } from "../../../assets/images/heSm.svg";
import { ReactComponent as Segment } from "../../../assets/images/segmentAvatar.svg";
import Checkbox from "../inputs/checkbox/Checkbox";
import AttributeList from "./AttributeList";
import SourceList from "./SourceList";
import { SelectedColumn } from "../../../services/view/viewEndpoints.interface";
import { Metrics, Traits } from "../../../services/data-index/dataIndexEndpoints.interface";

type Props = {
  list?: Traits[];
  metricsList?: Metrics[];
  selected: (string | SelectedColumn)[];
  setSelected: any;
  type?: string;
  children?: React.ReactNode;
  isOpen: any;
  setIsOpen: any;
  position?: "bottom" | "left" | "right" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start";
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const listOfSources = [
  {
    name: "all",
    label: "All Sources",
    icon: CubeTransparentIcon,
  },
  {
    name: "native",
    label: "Hyperengage",
    icon: He,
  },
  {
    name: "segment",
    label: "Segment",
    icon: Segment,
  },
];

const listOfAttributes = [
  {
    name: "all",
    label: "All",
    icon: CubeTransparentIcon,
  },
  {
    name: "traits",
    label: "Traits",
    icon: NewspaperIcon,
  },
  {
    name: "metrics",
    label: "Metrics",
    icon: PresentationChartBarIcon,
  },
];

const MultiSelectDropdown = ({
  list = [],
  metricsList = [],
  isOpen = false,
  setIsOpen,
  children,
  type = "Accounts",
  selected,
  setSelected,
  position = "left-start",
}: Props) => {
  const [query, setQuery] = useState<string>("");
  const [selectedSource, setSelectedSource] = useState(listOfSources[0].name);
  const [selectedAttribute, setSelectedAttribute] = useState<any>("all");
  const [queriedData, setQueriedData] = useState<(Traits | Metrics)[]>([]);

  const displayTraits = useMemo(() => (selectedSource === "all"
    ? list?.filter((item: any) => item?.name !== "accounts.name" && item?.name !== "users.name") : list?.filter((item: any) => item?.source === selectedSource && item?.name !== "accounts.name" && item?.name !== "users.name")
  ), [selectedSource, list]);

  const displayMetrics = useMemo(() => (selectedSource === "all"
    ? metricsList : metricsList?.filter(
      (item: any) => item?.source === selectedSource,
    )), [selectedSource, metricsList]);

  useEffect(() => {
    if (displayMetrics.length || displayTraits.length) {
      let data: (Traits | Metrics)[] = [];
      if (selectedAttribute === "metrics") {
        data = displayMetrics.filter(
          (item) => item?.display_name?.toLowerCase().includes(query.toLowerCase()),
        );
      }
      if (selectedAttribute === "traits") {
        data = displayTraits.filter(
          (item) => item?.display_name?.toLowerCase().includes(query.toLowerCase()),
        );
      }
      if (selectedAttribute === "all") {
        data = [...displayTraits, ...displayMetrics].filter(
          (item) => item?.display_name?.toLowerCase().includes(query.toLowerCase()),
        );
      }
      setQueriedData(data);
    }
    return () => {
      setQueriedData([]);
    };
  }, [query, selectedAttribute, displayMetrics.length, displayTraits.length]);

  const isOpenRef = useRef(isOpen); // Create a ref for the isOpen state
  isOpenRef.current = isOpen; // Update the ref whenever isOpen changes

  return (
    <Menu
      withinPortal
      closeOnClickOutside
      opened={isOpen}
      onChange={setIsOpen}
      position={position}
      shadow="md"
    >
      <Menu.Target>
        {children}
      </Menu.Target>
      <Menu.Dropdown className="z-6 p-0 origin-top-right">
        <div
          className="w-[600px] !overflow-hidden flex rounded-md bg-white"
        >
          <SourceList
            data={listOfSources}
            activeTab={selectedSource}
            handleTabChange={setSelectedSource}
          />
          <div className="w-[70%] border-l">
            <div className="w-full">
              <AttributeList
                data={listOfAttributes}
                activeTab={selectedAttribute}
                handleTabChange={setSelectedAttribute}
              />
            </div>
            <div className="flex items-center border-t">
              <div className="relative w-full">
                <div
                  className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  aria-hidden="true"
                >
                  <MagnifyingGlassIcon
                    className="mr-3 h-4 w-4 text-gray-500"
                    aria-hidden="true"
                  />
                </div>
                <input
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                  value={query}
                  type="text"
                  className="text-sm border-none focus:ring-0 placeholder:text-xs text-gray-500 w-full pl-9 hover:text-gray-700 placeholder:hover:text-gray-700 focus:text-gray-900 sm:text-sm"
                  placeholder={`Search ${type}`}
                />
              </div>
            </div>
            <div className="h-[250px] overflow-y-scroll border-t border-gray-200">
              {queriedData.length ? queriedData.map((item) => (
                <div
                  key={uuid()}
                  className={classNames(
                    "group",
                    "block w-full min-h-0 relative cursor-default select-none",
                  )}
                >
                  <div className="group-hover:bg-gray-300 bg-ease-linear duration-200  py-2 pl-3 pr-9">
                    <Checkbox
                      id={item?.object_definition ? `${item?.object_definition?.slug_name}.${item.data_key_slug}` : item.data_key_slug}
                      className=" !text-xs"
                      onChange={() => setSelected(item)}
                      checked={!!selected?.find(
                        (selectedItem) => (((typeof selectedItem === "string") ? selectedItem : selectedItem?.name) === (item?.object_definition ? `${item?.object_definition?.slug_name}.${item.data_key_slug}` : item.data_key_slug)),
                      )}
                    >
                      {item.display_name}
                    </Checkbox>
                  </div>
                </div>
              )) : (
                <Paper className="w-full bg-gray-100 h-full flex justify-center items-center">
                  <Text italic size="sm" color="text.3" className="font-semibold text-center">No Matching Items</Text>
                </Paper>
              )}
            </div>
          </div>
        </div>
      </Menu.Dropdown>
    </Menu>
  );
};

export default MultiSelectDropdown;
