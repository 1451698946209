/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { subDays } from "date-fns";
import { ITableProps, kaReducer } from "ka-table";
import {
  hideLoading, loadData, updateData, updatePageIndex, updatePagesCount,
} from "ka-table/actionCreators";
import { ActionType, SortDirection, SortingMode } from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useTraitsAndMetrics } from "../../../../hooks/QueryHooks";
import { getWorkspaceTrendsAgaintQuery } from "../../../../services/workspace/workspaceEndpoints";
import { ENTITY } from "../../../../services/workspace/workspaceEndpoints.enums";
import EmptyTablePlaceholder from "../../../../shared/table/EmptyTablePlaceHolder";
import KaTable, { IColumn, ISortBy } from "../../../../shared/table/KaTable";
import useUsersTableStore from "../../../../store/usersTable/usersTableStore";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";
import dateConverter from "../../../../utils/dateConverter";
import parseTableData from "../../../../utils/parseTable";
import UsersToolbar from "./components/UsersToolbar";

const AccountUsers = () => {
  const { usersTable, updateUsersTable } = useUsersTableStore();

  const params: any = useParams();
  const workspace = useWorkspaceStore((state) => state.workspace);
  const {
    data,
  } = useTraitsAndMetrics({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  const traits = useMemo(() => data?.contactTraits, [data?.contactTraits?.list?.length]);
  const metrics = useMemo(() => data?.contactMetrics, [data?.contactMetrics?.list?.length]);

  const [noResults, setNoResults] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const tableRef = useRef<any>(null);
  const [selectedCols, setSelectedCols] = useState<any[]>(usersTable.selected_fields);
  const [selectedMetrics, setSelectedMetrics] = useState<any[]>(usersTable.metrics);
  const [showHidden, setShowHidden] = useState(false);
  const [metric, setMetric] = useState<any>({ id: -1, name: "" });
  const allColumns = useMemo(() => ({ ...traits?.obj, ...metrics?.obj }), [metrics?.obj, traits?.obj]);
  const filteredTraits = useMemo(
    () => traits?.list?.filter((item) => !item.is_hidden),
    [traits?.list],
  );

  const filteredMetrics = useMemo(
    () => metrics?.list?.filter((item) => !item.is_hidden),
    [metrics?.list],
  );

  const [divRef] = useOutletContext<any>();

  const tableHeight = useMemo(
    () => (window.innerHeight - (divRef?.current?.offsetHeight || 60) - 60),
    [divRef],
  );

  const [tableProps, changeTableProps] = useState<ITableProps>({
    columns: [],
    columnResizing: true,
    paging: {
      enabled: true,
      pageIndex: 0,
      pagesCount: 1,
      pageSize: usersTable.per_page,
    },
    columnReordering: true,
    rowKeyField: "randomId",
    height: tableHeight,
    singleAction: loadData(),
    sortingMode: SortingMode.SingleRemote,
    loading: {
      enabled: true,
      text: "Loading",
    },
  });

  const onSortChange = useCallback((payload: { field: string, order: string, type?: string }) => {
    const { field, order } = payload;
    updateUsersTable({
      sort: {
        field,
        order,
        type: allColumns[field].attribute_type === "metric_traits" ? "growthval" : "property",
        data_type: allColumns[field].data_type,
        field_type: allColumns[field].entity_type,
      },
    });
  }, [traits?.obj, metrics?.obj]);

  const kaDispatch: DispatchFunc = (action: any) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    const sortColumn: any = tableProps.columns.find((c) => c.sortDirection);
    if (action.type === ActionType.UpdateSortDirection) {
      // change sort direction
      let newSortDirection = SortDirection.Ascend;
      if (action.columnKey === sortColumn?.key) {
        newSortDirection = sortColumn?.sortDirection === SortDirection.Ascend ? SortDirection.Descend : SortDirection.Ascend;
      }
      if (tableProps.sortingMode === SortingMode.SingleRemote) {
        onSortChange({
          field: action.columnKey,
          order: newSortDirection === SortDirection.Ascend ? "asc" : "desc",
          type: undefined,
        });
      }
    }
  };

  const fetchData = async (page: number, sortedBy: ISortBy) => {
    try {
      const response: any = await getWorkspaceTrendsAgaintQuery(
        {
          workspaceId: workspace?.id,
          entityType: ENTITY.USERS,
          query: {
            include_count: true,
            page: page + 1,
            per_page: usersTable.per_page,
            filter: {
              ...usersTable?.filter,
              query_type: "AND",
              predicates: [
                ...(usersTable?.filter?.predicates
                  ? usersTable.filter.predicates
                  : []),
                {
                  field: "accounts.external_id",
                  operator: "eq",
                  type: "Text",
                  value: params.accountId,
                  entity_type: "column",
                  entity_field_type: "default",
                },
              ],
            },
            selected_fields: usersTable.selected_fields,
            sort: {
              field: sortedBy?.key,
              order: sortedBy?.direction === SortDirection.Ascend ? "asc" : "desc",
              type: sortedBy?.type || (allColumns[sortedBy?.key].attribute_type === "metric_traits" ? "changeval" : "property"),
              data_type: allColumns[sortedBy?.key].data_type,
              field_type: allColumns[sortedBy?.key].entity_type!,
            },
            metrics: usersTable?.metrics,
            time_window: "all_time",
            time_zone: workspace?.workspace_time_zone,
          },
        },
      );
      if (response?.data?.length) {
        setNoResults(false);
        setTotal(response.total_results);
        const list = parseTableData({ data: response.data });
        kaDispatch(updateData(list));
        if (response.total_results < ((tableProps?.paging?.pageIndex || 0) * (tableProps?.paging?.pageSize || 1))) {
          kaDispatch(updatePageIndex(0));
        }
        kaDispatch(updatePagesCount(Math.ceil(response.total_results / (tableProps?.paging?.pageSize ?? 1)) || 0));
        kaDispatch(hideLoading());
      } else {
        setTotal(0);
        setNoResults(true);
        kaDispatch(updatePageIndex(0));
        kaDispatch(updateData([]));
        kaDispatch(updatePagesCount(1));
        kaDispatch(hideLoading());
      }
    } catch (err) {
      setTotal(0);
      setNoResults(true);
      kaDispatch(updatePageIndex(0));
      kaDispatch(updateData([]));
      kaDispatch(updatePagesCount(1));
      kaDispatch(hideLoading());
    }
  };

  const onSelectColumn = (item: any) => {
    if (item.attribute_type === "metric_traits") {
      setSelectedMetrics(selectedMetrics.includes(item.data_key_slug)
        ? selectedMetrics.filter((name: any) => name !== item.data_key_slug)
        : [...selectedMetrics, item.data_key_slug]);
      // Else we are setting it in selected columns
    } else {
      setSelectedCols(selectedCols?.find((col) => col.name === `${item.object_definition.slug_name}.${item.data_key_slug}`)
        ? selectedCols.filter((col) => col.name !== `${item.object_definition.slug_name}.${item.data_key_slug}`)
        : [...selectedCols, {
          name: `${item.object_definition.slug_name}.${item.data_key_slug}`,
          field_type: item.entity_type,
          data_type: item.data_type,
        }]);
    }
  };

  useEffect(() => {
    if (selectedCols?.length || selectedMetrics?.length) {
      updateUsersTable({ selected_fields: selectedCols, metrics: selectedMetrics });
    }
  }, [selectedCols, selectedMetrics]);

  useEffect(() => {
    if ((usersTable?.selected_fields?.length || usersTable?.metrics?.length)) {
      changeTableProps((state: any) => ({
        ...state,
        columns: [...(usersTable?.selected_fields || []), ...(usersTable?.metrics || [])]
          .reduce((arr, item) => {
            if (!allColumns[item?.name || item]
            || allColumns[item?.name || item]?.is_hidden) {
              return arr;
            }
            const dat: any = {
              key: item?.name || item,
              dataType: !(item?.name || item)?.includes("metric") ? allColumns[(item?.name || item)].data_type : "Text",
              title: allColumns[(item?.name || item)].display_name,
              isResizable: true,
              colGroup: { style: { minWidth: 50, width: 200 } },
              isSortable: true,
              attributeType: allColumns[(item?.name || item)]?.attribute_type,
            };
            if (usersTable?.sort?.field === item) {
              dat.sortType = usersTable?.sort?.type;
              dat.sortDirection = usersTable?.sort?.order === "asc" ? SortDirection.Ascend : SortDirection.Descend;
            }
            if ((item?.name || item)?.includes("name")) {
              arr.unshift(dat);
            } else {
              arr.push(dat);
            }
            return arr;
          }, [] as IColumn[]),
      }));
    }
    fetchData(tableProps?.paging?.pageIndex || 0, {
      key: usersTable?.sort?.field,
      direction: usersTable?.sort?.order === "asc" ? SortDirection.Ascend : SortDirection.Descend,
      type: usersTable?.sort?.type,
    });
  }, [usersTable, allColumns, tableProps?.paging?.pageIndex]);

  useEffect(() => {
    if (metric?.name) {
      const currentDateTime = new Date();
      const val = parseInt(
        metric.name.match(/[0-9]+/) ? metric.name.match(/[0-9]+/)[0] : "1",
        10,
      );
      const calcDate = subDays(currentDateTime, val);
      updateUsersTable({
        filter: {
          predicates: [
            {
              field: "last_seen",
              operator: "range",
              type: "Date",
              entity_field_type: "default",
              value: {
                start: dateConverter(calcDate),
                end: dateConverter(currentDateTime),
              },
              entity_type: "column",
            },
          ],
        },
      });
    }
  }, [metric.name]);

  return (
    <div className="relative flex flex-col gap-2 bg-white overflow-auto">
      <UsersToolbar
        selectedColumns={[...selectedCols, ...selectedMetrics]}
        setSelectedColumns={onSelectColumn}
        showHidden={showHidden}
        setShowHidden={setShowHidden}
        filteredTraits={filteredTraits}
        filteredMetrics={filteredMetrics}
        users={total}
        metric={metric}
        setMetric={setMetric}
      />
      {noResults ? (<EmptyTablePlaceholder height={tableHeight} />)
        : (
          <KaTable
            tableProps={tableProps}
            totalData={total}
            kaDispatch={kaDispatch}
            rowKeyField="randomId"
            changeTableProps={changeTableProps}
            ref={tableRef}
            itemType="Users"
          />
        )}
    </div>
  );
};

export default AccountUsers;
