/* eslint-disable react-hooks/exhaustive-deps */
import { CheckIcon, PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import {
  ActionIcon,
  Divider,
  Flex,
  Paper,
  Spoiler,
  Text,
} from "@mantine/core";
import { useMemo, useRef, useState } from "react";
import uuid from "react-uuid";
import { useTraitsAndMetrics } from "../../../../hooks/QueryHooks";
import MButton from "../../../../shared/ui/buttons/MButton";
import MultiSelectDropdown from "../../../../shared/ui/dropdown/MultiSelectDropdown";
import HeaderTrait from "./HeaderTraits";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";

const VisibleTraits = ({ account }: { account: any }) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [showHidden, setShowHidden] = useState(false);
  // const [displayedTraits, setDisplayedTraits] = useLocalStorageState<any>("he-accTr-prefs", {
  //   defaultValue: [
  //     ...currentWorkspace.workspace.preferences.accounts.pinned_traits,
  //   ],
  // });
  const [displayedTraits, setDisplayedTraits] = useState([
    "accounts.name",
    "accounts.arr",
    "account.avatar_url",
    "accounts.created_at",
    "accounts.csm_id",
    "accounts.first_payment_date",
    "accounts.is_ignored",
    "accounts.last_renewal_date",
    "accounts.mrr",
    "accounts.ae_id",
    "accounts.trial_expiry_date",
    "accounts.account_champion_id",
  ]);

  const onSelectTrait = (trait: any) => {
    setDisplayedTraits((selectedColumns: any) => (selectedColumns.includes(`${trait?.object_definition?.slug_name}.${trait?.data_key_slug}`)
      ? selectedColumns.filter((name: any) => name !== `${trait?.object_definition?.slug_name}.${trait?.data_key_slug}`)
      : [...selectedColumns, `${trait?.object_definition?.slug_name}.${trait?.data_key_slug}`]));
  };

  const originalTraits = useRef<string[]>(displayedTraits);
  const workspaceId = useWorkspaceStore((state) => state.workspace?.id);

  const { data }: any = useTraitsAndMetrics({
    workspaceId,
    enabled: !!workspaceId,
  });

  const accountsList = useMemo(() => data?.accountTraits?.list
  || [], [data?.accountTraits?.list?.length]);
  const shownTraits = useMemo(() => Object
    .entries(account || {}).reduce((obj: any, [trKey, trValue]: any) => {
      if (displayedTraits.some((item: any) => item === `accounts.${trKey}`)) {
        const selectedTrait = data?.accountTraits.obj[`accounts.${trKey}`];
        if (selectedTrait && !selectedTrait.exclude_display) {
          obj[trKey] = {
            ...selectedTrait,
            val: trValue,
          };
        }
      }
      return obj;
    }, {}), [account?.name,
    account?.arr,
    account?.avatar_url,
    account?.created_at,
    account?.csm_id,
    account?.first_payment_date,
    account?.is_ignored, account
      ?.last_renewal_date,
    account?.mrr,
    account?.ae_id,
    account?.trial_expiry_date,
    account?.account_champion_id,
    displayedTraits,
    data?.accountTraits.obj]);

  const editHandler = () => {
    setEdit(!edit);
  };

  const handleDiscard = () => {
    setDisplayedTraits(originalTraits.current);
    setEdit(false);
  };

  const handleSave = () => {
    originalTraits.current = displayedTraits;
    setEdit(false);
  };

  const deleteHandler = (trait: any) => {
    setDisplayedTraits(displayedTraits.filter((item: any) => item !== trait));
  };

  const handleHidden = () => {
    setShowHidden((state) => !state);
  };

  return (
    <Paper
      shadow="xs"
      radius="sm"
      className="flex-grow"
    >
      <div className="flex items-center justify-between p-3">
        <Text size="sm" color="gray.6" className="font-semibold">
          TRAITS
        </Text>
        {!edit && <MButton color="white" size="xs" variant="outline" onClick={editHandler} radius="sm">Edit traits</MButton>}
        {edit && (
        <div className="flex items-center gap-2">
          <ActionIcon
            variant="outline"
            color="red"
            size="md"
            className="hover:bg-red-100"
            onClick={handleDiscard}
          >
            <XMarkIcon width={25} height={25} />
          </ActionIcon>
          <ActionIcon
            variant="filled"
            color="green"
            className="bg-emerald-500 hover:bg-emerald-600"
            size="md"
            onClick={handleSave}
          >
            <CheckIcon width={25} height={25} />
          </ActionIcon>
        </div>
        )}
      </div>
      <Divider className="border-gray-200" />
      <Spoiler
        classNames={{ root: "p-1", control: "pl-3 text-xs" }}
        maxHeight={120}
        showLabel="Show more"
        hideLabel="Hide"
      >
        <Flex wrap="wrap" className="gap-x-4 gap-y-1">
          {Object.values(shownTraits).map(
            (value: any) => (
              <HeaderTrait
                edit={edit}
                handleDelete={deleteHandler}
                key={uuid()}
                trait={value}
              />
            ),
          )}
        </Flex>
      </Spoiler>
      {edit && (
      <MultiSelectDropdown
        selected={displayedTraits}
        position="bottom-start"
        setSelected={onSelectTrait}
        isOpen={showHidden}
        setIsOpen={setShowHidden}
        list={accountsList}
      >
        <div>
          <MButton size="xs" onClick={handleHidden} radius="sm" className="ml-3 my-4" leftIcon={<PlusIcon width={16} height={16} />} variant="outline" color="white">Add new trait</MButton>
        </div>
      </MultiSelectDropdown>
      )}
    </Paper>
  );
};

export default VisibleTraits;
