/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import {
  ActionIcon,
  Collapse,
  Flex,
  LoadingOverlay,
  Stack,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import uuid from "react-uuid";
import { useMemo, useState } from "react";
import { useAuthInfo } from "@propelauth/react";
import { queryClient, useWorkspaceMembers } from "../../../../hooks/QueryHooks";
import AddUserDropdown from "./AddUserDropdown";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";
import notify from "../../../../utils/notify";
import { assignKeyRole } from "../../../../services/companyEndpoint";

type Props = {
  owner?: string,
  csm?: string,
  ae?: string,
  contacts?: any[],
  accountId: string,
  containerClass?: string,
  collapsible?: boolean,
  defaultOpen?: boolean,
};

const KeyRole = ({
  owner, csm, ae, contacts, accountId, containerClass, collapsible = false,
  defaultOpen = true,
}: Props) => {
  const workspace = useWorkspaceStore((state) => state.workspace);
  const [customerSM, setCustomerSM] = useState<string | null>(csm || null);
  const [accountE, setAccountE] = useState<string | null>(ae || null);
  const [own, setOwn] = useState<string | null>(owner || null);
  const { accessHelper } = useAuthInfo();
  const [opened, handlers] = useDisclosure(defaultOpen);
  const { data: workspaceMembers, isLoading }: any = useWorkspaceMembers({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
    retry: 2,
  });

  const csmList = useMemo(() => workspaceMembers?.users?.map((member: any) => ({
    value: member.user_id,
    label: `${member.first_name} ${member.last_name}`,
    name: `${member.first_name} ${member.last_name}`,
    email: member.email,
    avatar_url: member.avatar_url,
  })) || [], [workspaceMembers?.users?.length]);

  const ownerList = useMemo(() => contacts?.map((member: any) => ({
    value: member.external_id,
    label: member.name,
    name: member.name,
    email: member.email,
    avatar_url: member.avatar_url,
  })) || [], [contacts?.length]);

  const handleCsmSelect = async (user: any) => {
    setCustomerSM(user);
    try {
      await assignKeyRole({
        account_id: accountId,
        assign_to_user_id: user,
        key_role_id: workspace?.key_roles?.find((role: any) => role.name === "CSM")?.id,
        workspace_identifier: workspace?.id,
      });
      queryClient.setQueryData(["account", workspace?.id, accountId], (oldData: any) => ({ ...oldData, csm_id: user }));
      notify({
        messageList: ["Assigned CSM Successfully"],
        type: "success",
      });
    } catch {
      notify({
        messageList: ["Failed to assign CSM, please try again later"],
        type: "failure",
      });
    }
  };

  const handleAeSelect = async (user: any) => {
    setAccountE(user);
    try {
      await assignKeyRole({
        account_id: accountId,
        assign_to_user_id: user,
        key_role_id: workspace?.key_roles?.find((role: any) => role.name === "Account Executive")?.id,
        workspace_identifier: workspace?.id,
      });
      queryClient.setQueryData(["account", workspace?.id, accountId], (oldData: any) => ({
        ...oldData,
        ae_id: user,
      }));
      notify({
        messageList: ["Assigned AE Successfully"],
        type: "success",
      });
    } catch (err) {
      notify({
        messageList: ["Failed to assign AE, please try again later"],
        type: "failure",
      });
    }
  };

  const handleOwnerSelect = async (user: any) => {
    setOwn(user);
    try {
      await assignKeyRole({
        account_id: accountId,
        assign_to_user_id: user,
        key_role_id: workspace?.key_roles?.find((role: any) => role.name === "Account Owner")?.id,
        workspace_identifier: workspace?.id,
      });
      queryClient.setQueryData(["account", workspace?.id, accountId], (oldData: any) => ({ ...oldData, owner_id: user }));
      notify({
        messageList: ["Assigned Owner Successfully"],
        type: "success",
      });
    } catch {
      notify({
        messageList: ["Failed to assign Owner, please try again later"],
        type: "failure",
      });
    }
  };

  const ownerUser = useMemo(() => ownerList
    ?.find((member: any) => member?.value === own), [own, ownerList]);
  const csmUser = useMemo(() => csmList
    ?.find((member: any) => member?.value === customerSM), [customerSM, csmList]);
  const aeUser = useMemo(() => csmList
    ?.find((member: any) => member?.value === accountE), [accountE, csmList]);

  return (
    <Stack h={215} className={`border shrink-0 rounded-md relative ${containerClass || ""}`} bg="white" px={15} spacing="sm" w={300} align="stretch">
      <Flex p={16} mx={-15} className="border-b" align="center" justify="space-between">
        <Text size="xs" tt="uppercase" c="text.5" fw={600} lts={0.6}>Key roles</Text>
        {collapsible && (
        <ActionIcon onClick={handlers.toggle} color="text.5" size="xs">
          <ChevronUpIcon style={{ transform: opened ? "rotate(0)" : "rotate(180deg)" }} />
        </ActionIcon>
        )}
      </Flex>
      <Collapse in={opened}>
        <LoadingOverlay loaderProps={{ size: "sm" }} c="main.5" visible={isLoading} overlayBlur={2} />
        <Stack spacing="sm" className="pb-2">
          <Flex align="center" justify="space-between">
            <Text className="whitespace-nowrap w-[60px]" fz="sm" fw="600" c="text.4">
              CSM
            </Text>
            <AddUserDropdown
              disabled={!accessHelper?.hasPermission(workspace?.id, "can_remove_key_role")}
              value={customerSM}
              selected={csmUser}
              placeHolder="Select CSM"
              key={uuid()}
              users={csmList}
              onUserSelect={handleCsmSelect}
            />
          </Flex>
          <Flex align="center" justify="space-between">
            <Text className="whitespace-nowrap w-[60px]" fz="sm" fw="600" c="text.4">
              Owner
            </Text>
            <AddUserDropdown
              disabled={!accessHelper?.hasPermission(workspace?.id, "can_remove_key_role")}
              value={own}
              selected={ownerUser}
              placeHolder="Select Owner"
              key={uuid()}
              users={ownerList}
              onUserSelect={handleOwnerSelect}
            />
          </Flex>
          <Flex align="center" justify="space-between">
            <Text className="whitespace-nowrap w-[60px]" fz="sm" fw="600" c="text.5">
              AE
            </Text>
            <AddUserDropdown
              disabled={!accessHelper?.hasPermission(workspace?.id, "can_remove_key_role")}
              value={accountE}
              selected={aeUser}
              key={uuid()}
              placeHolder="Select AE"
              users={csmList}
              onUserSelect={handleAeSelect}
            />
          </Flex>
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default KeyRole;
