/* eslint-disable react-hooks/exhaustive-deps */
import { CheckIcon, PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import {
  ActionIcon,
  Divider,
  Flex,
  Paper,
  Spoiler,
  Text,
} from "@mantine/core";
import { useMemo, useRef, useState } from "react";
import uuid from "react-uuid";
import { useTraitsAndMetrics } from "../../../../../hooks/QueryHooks";
import MButton from "../../../../../shared/ui/buttons/MButton";
import MultiSelectDropdown from "../../../../../shared/ui/dropdown/MultiSelectDropdown";
import HeaderTrait from "../../components/HeaderTraits";
import useWorkspaceStore from "../../../../../store/workspace/workspaceStore";

const VisibleTraits = ({ contact }: { contact: any }) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [showHidden, setShowHidden] = useState(false);
  const [displayedTraits, setDisplayedTraits] = useState([
    "users.name",
    "users.email",
    "users.last_seen",
    "users.linkedin_profile_url",
    "users.location_country",
    "users.creation_source",
    "users.last_viewed_page",
  ]);
  const workspaceId = useWorkspaceStore((state) => state.workspace?.id);

  const onSelectTrait = (trait: any) => {
    setDisplayedTraits((selectedColumns: any) => (selectedColumns.includes(`${trait?.object_definition?.slug_name}.${trait?.data_key_slug}`)
      ? selectedColumns.filter((name: any) => name !== `${trait?.object_definition?.slug_name}.${trait?.data_key_slug}`)
      : [...selectedColumns, `${trait?.object_definition?.slug_name}.${trait?.data_key_slug}`]));
  };

  const originalTraits = useRef<string[]>(displayedTraits);

  const { data }: any = useTraitsAndMetrics({
    workspaceId,
    enabled: !!workspaceId,
  });

  const shownTraits = useMemo(() => Object
    .entries(contact || {}).reduce((obj: any, [trKey, trValue]: any) => {
      if (displayedTraits?.some((item: any) => item === `users.${trKey}`)) {
        const selectedTrait = data?.contactTraits?.obj[`users.${trKey}`];
        if (selectedTrait && !selectedTrait.exclude_display) {
          obj[trKey] = {
            ...selectedTrait,
            val: trValue,
          };
        }
      }
      return obj;
    }, {}), [
    contact?.first_name,
    contact?.last_name,
    contact?.email,
    contact?.last_seen,
    contact?.linkedin_profile_url,
    contact?.location_country,
    contact?.creation_source,
    contact?.last_viewed_page,
    displayedTraits,
    data?.contactTraits?.obj]);

  const editHandler = () => {
    setEdit(!edit);
  };

  const handleDiscard = () => {
    setDisplayedTraits(originalTraits.current);
    setEdit(false);
  };

  const handleSave = () => {
    originalTraits.current = displayedTraits;
    setEdit(false);
  };

  const deleteHandler = (trait: any) => {
    setDisplayedTraits(displayedTraits.filter((item: any) => item !== trait));
  };

  const handleHidden = () => {
    setShowHidden((state) => !state);
  };

  return (
    <Paper
      shadow="xs"
      radius="sm"
      className="flex-grow"
    >
      <div className="flex items-center justify-between p-3">
        <Text size="sm" color="gray.6" className="font-semibold">
          TRAITS
        </Text>
        {!edit && <MButton color="white" size="xs" variant="outline" onClick={editHandler} radius="sm">Edit traits</MButton>}
        {edit && (
        <div className="flex items-center gap-2">
          <ActionIcon
            variant="outline"
            color="red"
            size="md"
            className="hover:bg-red-100"
            onClick={handleDiscard}
          >
            <XMarkIcon width={25} height={25} />
          </ActionIcon>
          <ActionIcon
            variant="filled"
            color="green"
            className="bg-emerald-500 hover:bg-emerald-600"
            size="md"
            onClick={handleSave}
          >
            <CheckIcon width={25} height={25} />
          </ActionIcon>
        </div>
        )}
      </div>
      <Divider className="border-gray-200" />
      <Spoiler
        classNames={{ root: "p-2", control: "pl-3 text-xs" }}
        maxHeight={120}
        showLabel="Show more"
        hideLabel="Hide"
      >
        <Flex wrap="wrap" className="gap-x-4 gap-y-1">
          {Object.values(shownTraits).map(
            (value: any) => (
              <HeaderTrait
                edit={edit}
                handleDelete={deleteHandler}
                key={uuid()}
                trait={value}
              />
            ),
          )}
        </Flex>
      </Spoiler>
      {edit && (
      <MultiSelectDropdown
        selected={displayedTraits}
        position="bottom-start"
        setSelected={onSelectTrait}
        isOpen={showHidden}
        setIsOpen={setShowHidden}
        list={data?.contactTraits?.list || []}
      >
        <div>
          <MButton size="xs" onClick={handleHidden} radius="sm" className="ml-3 my-4" leftIcon={<PlusIcon width={16} height={16} />} variant="outline" color="white">Add new trait</MButton>
        </div>
      </MultiSelectDropdown>
      )}
    </Paper>
  );
};

export default VisibleTraits;
