/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import React from "react";
import uuid from "react-uuid";
import { Text } from "@mantine/core";
import AvatarWithLink from "../ui/avatar/Avatar";
import Pill from "../ui/badges/Pill";
import Trait from "./Trait";

type Props = {
  source: string,
  data: any,
  edit?:boolean,
};

const TraitSource = ({ source, data, edit = false }: Props) => (
  <div className="flex flex-wrap gap-x-[12%] gap-y-5">
    <Text truncate className="w-full text-xs mb-1 uppercase text-gray-500">
      {source}
      {" "}
      traits
    </Text>
    {Object.entries(data).map(([key, value]: any) => {
      if (value?.type === "user") {
        return (
          <Trait title={value?.display_name} key={uuid()}>
            {value?.val?.name
              ? (
                <AvatarWithLink
                  name={value?.val?.name}
                  img={value?.val?.img}
                  size="sm"
                  rounded
                />
              ) : (<span className="mt-2 h-[5px] bg-gray-200 w-[40px] rounded-lg" />)}
          </Trait>
        );
      }

      if (value?.type === "badge") {
        return (
          <Trait title={value?.display_name} key={uuid()}>
            <Pill
              Icon={ChevronDownIcon}
              text={value.val}
              onClick={() => {}}
              color="red"
              size="md"
            />
          </Trait>
        );
      }

      return (<Trait key={uuid()} title={value?.display_name}>{value?.val ? value.val : (<span className="mt-2 h-[5px] bg-gray-200 w-[40px] rounded-lg" />)}</Trait>);
    })}
  </div>
);

export default TraitSource;
