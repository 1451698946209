/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import {
  Flex,
  Modal,
  Paper,
  Stack,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { RichTextEditor } from "@mantine/tiptap";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { format } from "date-fns";
import DOMPurify from "dompurify";
import { useEffect, useMemo, useState } from "react";
import { IconPencil } from "@tabler/icons-react";
import { useTraitsAndMetrics } from "../../../../../hooks/QueryHooks";
import LabelWithTooltip from "../../../../../shared/label/LabelWithTooltip";
import MButton from "../../../../../shared/ui/buttons/MButton";
import MultiSelectDropdown from "../../../../../shared/ui/dropdown/MultiSelectDropdown";
import useWorkspaceStore from "../../../../../store/workspace/workspaceStore";
import notify from "../../../../../utils/notify";
import HeButton from "../../../../../shared/ui/buttons/HeButton";

type Props = {
  onCompleteAction: (action: any, type: string) => void;
  opened: boolean;
  close: () => void;
  data?: any;
  playbook?: any;
  setSelectedFields?: any;
  signal?: any;
  setSelectedMetrics?: any;
};

const EmailModal = ({
  onCompleteAction, opened, close, data, playbook, setSelectedFields, signal, setSelectedMetrics,
}: Props) => {
  const [showHidden, setShowHidden] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [to, setTo] = useState(data?.to || "");
  const [wrongEmail, setWrongEmail] = useState<string | null>(null);
  const [subject, setSubject] = useState(data?.subject || "");
  const workspace = useWorkspaceStore((state: any) => state.workspace);

  const traitsAndMetrics: any = useTraitsAndMetrics({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  const filteredTraits = useMemo(
    () => (signal?.entity_relation === "accounts" ? traitsAndMetrics?.data?.accountTraits?.list?.filter((item: any) => !item.exclude_display) : traitsAndMetrics?.data?.contactTraits?.list?.filter((item: any) => !item.exclude_display)),
    [signal?.entity_relation, traitsAndMetrics?.isSettled],
  );

  const filteredMetrics = useMemo(
    () => (signal?.entity_relation === "accounts" ? traitsAndMetrics?.data?.accountMetrics?.list?.filter((item: any) => !item.exclude_display) : traitsAndMetrics?.data?.contactMetrics?.list?.filter((item: any) => !item.exclude_display)),
    [signal?.entity_relation, traitsAndMetrics?.isSettled],
  );

  const onSelectColumn = (item: any) => {
    if (item.attribute_type === "metric_traits") {
      setSelectedMetrics(signal?.metrics.includes(item.data_key_slug)
        ? signal?.metrics.filter((name: any) => name !== item.data_key_slug)
        : [...(signal?.metrics || []), item.data_key_slug]);
      // Else we are setting it in selected columns
    } else {
      setSelectedFields(signal?.selected_fields?.find((col: any) => col.name === `${item.object_definition.slug_name}.${item.data_key_slug}`)
        ? signal?.selected_fields.filter((col: any) => col.name !== `${item.object_definition.slug_name}.${item.data_key_slug}`)
        : [...(signal?.selected_fields || []), {
          name: `${item.object_definition.slug_name}.${item.data_key_slug}`,
          field_type: item.entity_type,
          data_type: item.data_type,
        }]);
    }
  };

  const sanitizedHTML = DOMPurify.sanitize(playbook?.content || "");
  const editor = useEditor({
    editable: false,
    extensions: [
      Underline,
      TaskList.configure({
        HTMLAttributes: {
          class: "!pl-0",
        },
      }),
      TaskItem.configure({
        nested: true,
        HTMLAttributes: {
          class: "flex gap-3",
        },
      }),
      Link,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3, 4],
        },
        bulletList:
        {
          HTMLAttributes: {
            class: "list-disc list-outside",
          },
        },
        orderedList:
        {
          HTMLAttributes: {
            class: "list-decimal list-outside",
          },
        },
      }),
    ],
    content: sanitizedHTML,
  });

  const validateEmails = () => {
    const emailArray = to.split(",");
    setWrongEmail(null);
    emailArray.forEach((email: any) => {
      email = email.trim();
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailRegex.test(email)) {
        setWrongEmail(`Invalid email: ${email}`);
      }
    });
  };

  useEffect(
    () => {
      if (data) {
        setTo(data?.to);
        setSubject(data?.subject);
      }
    },
    [data],
  );

  useEffect(() => {
    validateEmails();
  }, [to]);

  useEffect(
    () => {
      editor?.commands?.setContent(sanitizedHTML);
    },
    [playbook?.content],
  );

  return (
    <Modal
      padding="lg"
      classNames={{
        title: "text-gray-900 text-xl font-bold",
      }}
      size={700}
      opened={opened}
      centered
      onClose={() => {
        close();
      }}
      title={(
        <Flex align="center">
          <EnvelopeIcon className="w-6 h-6 mr-4 text-gray-600" />
          <Stack spacing={0}>
            <Text>Email | Send message</Text>
            <Text size="xs" c="text.5" weight={500}>
              Send a custom message to the specified recipient.
            </Text>
          </Stack>
        </Flex>
)}
    >
      <TextInput
        classNames={{
          label: "text-gray-500 font-bold mb-1 text-xs",
        }}
        label={(
          <LabelWithTooltip
            label="To"
            info="Enter a list of comma-separated emails. e.g `example1@gmail.com, example2@gmail.com`"
          />
)}
        placeholder="Enter email address(es) separated by commas"
        value={to}
        onChange={(event: any) => {
          setTo(event?.currentTarget.value);
        }}
        error={error && wrongEmail ? (to === "" ? "To field must not be empty" : wrongEmail) : null}
      />

      <Textarea
        classNames={{
          label: "text-gray-500 font-bold mb-1 text-xs",
        }}
        label={(
          <LabelWithTooltip
            label="Subject"
            info="Enter the subject of the email notification so your team can easily identify it in their mailbox, by default we use the title of the signal as subject"
          />
)}
        placeholder="Enter an alert message"
        value={subject}
        onChange={(event: any) => setSubject(event?.currentTarget.value)}
        error={error && subject === "" ? "Subject must not be empty" : null}
      />
      <MultiSelectDropdown
        selected={[...signal.selected_fields, ...signal.metrics]}
        setSelected={onSelectColumn}
        isOpen={showHidden}
        setIsOpen={setShowHidden}
        list={filteredTraits}
        metricsList={filteredMetrics}
        position="bottom-end"
      >
        <div>
          <MButton
            color="white"
            onClick={() => { setShowHidden((state) => !state); }}
            leftIcon={<IconPencil />}
            w="100%"
            mt={10}
          >
            Edit Columns
          </MButton>
        </div>
      </MultiSelectDropdown>
      <Text className="text-gray-500 font-bold text-xs my-2">Preview</Text>
      <Paper withBorder className="bg-gray-50 max-w-full" shadow="xs">
        <Flex p="md" align="center" gap={5} className="w-full bg-purple-100">
          <EnvelopeIcon className="w-4 h-4 mr-2 shrink-0 text-gray-600" />
          <Stack spacing={3}>
            <Text size="sm" c="text.7" weight={600}>
              To:
              {"  "}
              <Text lineClamp={1} className="ml-1" span c="main.6" size="xs">
                {to || "Select recipient(s)"}
              </Text>
            </Text>
          </Stack>
          <Text className="ml-auto text-xs text-gray-500">{format(new Date(), "hh:mm a")}</Text>
        </Flex>
        <Text p="md" pb={0} maw={300} size="sm" c="text.5" lineClamp={5} className="pb-4 border-gray-300">
          <Text weight={700} size="xs">
            Subject:
            {" "}
            {subject || "Enter an email subject"}
          </Text>
        </Text>
        <RichTextEditor
          classNames={{
            typographyStylesProvider: "text-xs",
            root: "border-none",
          }}
          editor={editor}
        >
          <RichTextEditor.Content />
        </RichTextEditor>
      </Paper>
      <Flex justify="flex-end" gap={5} className="mt-4">
        <MButton
          onClick={() => {
            close();
          }}
          variant="outline"
          className="hover:bg-red-100"
          color="red"
        >
          Cancel
        </MButton>
        <MButton
          color="purple"
          onClick={
              () => {
                validateEmails();
                if (subject !== "" && to !== "" && !wrongEmail) {
                  onCompleteAction({
                    type: "send_to_email",
                    to,
                    subject,
                  }, "send_to_email");
                  notify({
                    messageList: ["Email action added successfully"],
                    type: "info",
                  });
                  close();
                } else {
                  setError(true);
                }
              }
          }
        >
          Save Action
        </MButton>
      </Flex>
    </Modal>
  );
};

export default EmailModal;
