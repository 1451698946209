/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import {
  ActionIcon, Flex, Text, Tooltip, CopyButton as Copy,
} from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import { useAllIntegrations } from "../../../../hooks/QueryHooks";
import useCheckEvents from "../../../../hooks/useCheckEvents";
import { updateIntegration } from "../../../../services/integrationEndpoint";
import { updateWorkspace } from "../../../../services/workspace/workspaceEndpoints";
import StepWithProgressbar from "../../../../shared/step-with-progressbar/StepWithProgresssbar";
import HeButton from "../../../../shared/ui/buttons/HeButton";
import notify from "../../../../utils/notify";
import CopyButton from "./CopyButton";
import StepFooter from "./StepFooter";

const findStatus = (active: number, current: number) => {
  if (active > current) {
    return "complete";
  }
  if (active < current) {
    return "upcoming";
  }

  return "current";
};

type Props = {
  data: {
    apiKey: any,
    workspace: any,
  },
  steps: {
    script: string,
    title: string,
    script2?:string,
    desc: string,
  }[] | [],
  api?: boolean;
};

const NativeSteps = ({ data, steps, api }: Props) => {
  const { data: integrations }: any = useAllIntegrations({
    workspaceId: data.workspace?.id,
    enabled: !!data.workspace?.id && !!data?.apiKey?.id,
    select: (int: any) => int?.data?.filter((item: any) => item?.source_name === "native")[0],
    onError: () => {
      notify({
        heading: "Failed to fetch Integrations",
        messageList: ["Please try again later or contact support to help you resolve this issue"],
        type: "failure",
      });
    },
  });

  const checkEvents: any = useCheckEvents({
    token: data.apiKey?.id,
    integrationName: "native",
    workspaceId: data.workspace?.id,
  });

  const [activeStep, setActiveStep] = useState<number>(1);
  const [activeModal, setActiveModal] = useState<number>(1);
  const navigate = useNavigate();
  const [step1, step2, step3, step4] = steps;

  const onClick = (stepNo: number) => {
    if (activeStep !== stepNo) {
      setActiveModal(stepNo);
      setActiveStep(stepNo);
    } else {
      setActiveModal((state) => (state === stepNo ? -1 : stepNo));
    }
  };

  const nextHandler = () => {
    setActiveModal(activeStep + 1);
    setActiveStep((state) => (state + 1));
  };

  const prevHandler = () => {
    if (activeStep !== 1) {
      setActiveModal(activeStep - 1);
      setActiveStep((state) => (state - 1));
    }
  };

  return (
    <nav aria-label="Progress" className="flex flex-col h-full">
      {steps?.length ? (
        <ol className="overflow-auto flex flex-col gap-4 p-12 pb-2 sm:p-10">
          <StepWithProgressbar
            status={findStatus(activeStep, 1)}
            isOpen={activeModal === 1}
            onClick={onClick}
            stepNo={1}
            desc={step1.desc}
            title={step1.title}
          >
            <div className="flex flex-col gap-3">
              {api ? (
                <Flex gap={5} align="center">
                  <Text className="text-sm text-gray-900 font-bold">Workspace Identifier:</Text>
                  <SyntaxHighlighter language="text" customStyle={{ fontSize: "13px" }} style={docco}>
                    {`${step1.script}`}
                  </SyntaxHighlighter>
                  <Copy timeout={2000} value={data.apiKey?.server_analytics_key}>
                    {({ copied, copy }: any) => (
                      <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                        <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                          {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </Copy>
                </Flex>
              ) : (
                <>
                  <SyntaxHighlighter showLineNumbers language="html" customStyle={{ fontSize: "13px" }} style={docco}>
                    {step1.script}
                  </SyntaxHighlighter>
                  <CopyButton textToCopy={step1.script} />
                </>
              )}
              {step1?.script2 && (api ? (
                <Flex gap={5} align="center">
                  <Text className="text-sm text-gray-900 font-bold">API Key:</Text>
                  <SyntaxHighlighter language="text" customStyle={{ fontSize: "13px" }} style={docco}>
                    {`${step1.script2}`}
                  </SyntaxHighlighter>
                  <Copy timeout={2000} value={data.apiKey?.server_analytics_key}>
                    {({ copied, copy }: any) => (
                      <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                        <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                          {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </Copy>
                </Flex>
              ) : (
                <>
                  <SyntaxHighlighter showLineNumbers language="html" customStyle={{ fontSize: "13px" }} style={docco}>
                    {step1.script2}
                  </SyntaxHighlighter>
                  <CopyButton textToCopy={step1.script} />
                </>
              ))}
              {api && (
              <>
                <Text className="text-sm text-gray-500 font-medium">You will have to send the request to the following endpoint. You can post it with the user_identify, account_identify and track events. You can also send events in bulk inside an array.</Text>
                <SyntaxHighlighter showLineNumbers language="curl" customStyle={{ fontSize: "13px" }} style={docco}>
                  {`https://events.hyperengage.io/api/v1/s2s/event?token=${data?.apiKey?.server_analytics_key}`}
                </SyntaxHighlighter>
                <CopyButton textToCopy={step1.script} />
              </>
              )}
              <StepFooter
                helpTo="https://docs.hyperengage.io/quickstart/install"
                nextHandler={nextHandler}
                backHandler={prevHandler}
                backButtonVisible={false}
              />
            </div>
          </StepWithProgressbar>
          <StepWithProgressbar
            status={findStatus(activeStep, 2)}
            isOpen={activeModal === 2}
            onClick={onClick}
            stepNo={2}
            desc={
              (
                <>
                  After connecting your data source with the base tracking script send over your account/company data
                  {" "}
                  <span className="text-yellow-500">(optional)</span>
                  {" "}
                  <Tooltip
                    multiline
                    classNames={{
                      tooltip: "text-xs w-[200px] p-2",
                    }}
                    position="bottom"
                    withArrow
                    label="Accounts or Companies depend on your data model. Generally they are groups of users that you sell to which, collectively, can be a workspace, organization or accounts."
                  >
                    <Text className="cursor-help italic" c="main.8" weight={500} span>?</Text>
                  </Tooltip>
                </>
)
              }
            title={step2.title}
          >
            <div className="flex flex-col gap-3">
              <SyntaxHighlighter showLineNumbers language="json" customStyle={{ fontSize: "13px" }} style={docco}>
                {step2.script}
              </SyntaxHighlighter>
              <CopyButton textToCopy={step2.script} />
              {checkEvents.data?.identify_account?.length ? (
                <div>
                  <h3 className="text-gray-500">Account data received successfully. Check preview below :</h3>
                  <SyntaxHighlighter showLineNumbers language="javascript" customStyle={{ fontSize: "13px" }} style={docco}>
                    {Object.entries(checkEvents.data?.identify_account?.[0]).map(([key, val]) => (`${key}: "${val}"`)).join("\n")}
                  </SyntaxHighlighter>
                </div>
              ) : (
                <div className="flex flex-col gap-2 items-center justify-center bg-violet-50 p-3">
                  <span className="flex h-3 w-3 relative">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75" />
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-purple-500" />
                  </span>
                  <p className="text-gray-700 text-sm">Waiting to receive account events...</p>
                </div>
              )}
              <StepFooter
                helpTo="https://docs.hyperengage.io/quickstart/identify"
                nextHandler={nextHandler}
                backHandler={prevHandler}
              />
            </div>
          </StepWithProgressbar>
          <StepWithProgressbar
            status={findStatus(activeStep, 3)}
            isOpen={activeModal === 3}
            onClick={onClick}
            stepNo={3}
            desc={step3.desc}
            title={step3.title}
          >
            <div className="flex flex-col gap-3">
              <SyntaxHighlighter showLineNumbers language="json" customStyle={{ fontSize: "13px" }} style={docco}>
                {step3.script}
              </SyntaxHighlighter>
              <CopyButton textToCopy={step3.script} />

              {checkEvents?.data?.identify_user?.length ? (
                <>
                  <h3 className="text-gray-500">User data received successfully. Check preview below :</h3>
                  <SyntaxHighlighter showLineNumbers language="html" customStyle={{ fontSize: "13px" }} style={docco}>
                    {Object.entries(checkEvents?.data?.identify_user[0]).map(([key, val]) => (`${key}: "${val}"`)).join("\n")}
                  </SyntaxHighlighter>
                </>
              ) : (
                <div className="flex flex-col gap-2 items-center justify-center bg-violet-50 p-3">
                  <span className="flex h-3 w-3 relative">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75" />
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-purple-500" />
                  </span>
                  <p className="text-gray-700 text-sm">Waiting to receive user events...</p>
                </div>
              )}
              <StepFooter
                helpTo="https://docs.hyperengage.io/quickstart/identify"
                nextHandler={nextHandler}
                backHandler={prevHandler}
              />
            </div>
          </StepWithProgressbar>
          <StepWithProgressbar
            status={findStatus(activeStep, 4)}
            last
            isOpen={activeModal === 4}
            onClick={onClick}
            stepNo={4}
            desc={step4.desc}
            title={step4.title}
          >
            <div className="flex flex-col gap-3">
              <SyntaxHighlighter showLineNumbers language="json" customStyle={{ fontSize: "13px" }} style={docco}>
                {step4.script}
              </SyntaxHighlighter>
              <CopyButton textToCopy={step4.script} />
              {checkEvents.data?.track?.length ? (
                <>
                  <h3 className="text-gray-500">Events data received successfully. Check preview below :</h3>
                  <SyntaxHighlighter showLineNumbers language="javascript" customStyle={{ fontSize: "13px" }} style={docco}>
                    {Object.entries(checkEvents.data?.track[0]).map(([key, val]) => (`${key}: "${val}"`)).join("\n")}
                  </SyntaxHighlighter>
                </>
              ) : (
                <div className="flex flex-col gap-2 items-center justify-center bg-violet-50 p-3">
                  <span className="flex h-3 w-3 relative">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75" />
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-purple-500" />
                  </span>
                  <p className="text-gray-700 text-sm">Waiting to receive track events...</p>
                </div>
              )}
              <StepFooter
                helpTo="https://docs.hyperengage.io/quickstart/track"
                nextHandler={nextHandler}
                backHandler={prevHandler}
                nextButtonVisible={false}
              />
            </div>
          </StepWithProgressbar>
        </ol>
      ) : (<h2 className="w-full h-full flex items-center justify-center animate-pulse text-primary-900 text-[25px]">Coming Soon...</h2>)}
      <div className="flex justify-end py-5 border-t-2 mt-auto px-12 sm:px-10">
        <span className="inline-flex gap-3 items-center">
          <HeButton text="Back" size="sm" color="white" onClick={() => { navigate(-1); }} />
          <HeButton
            text="Continue"
            disabled={!checkEvents?.data?.identify_user?.length || !checkEvents?.data?.track?.length}
            size="sm"
            color="purple"
            onClick={async () => {
              if (data?.workspace?.id) {
                await updateIntegration({
                  created_by_user_id: integrations?.created_by_user_id,
                  source_name: integrations.source_name,
                  id: integrations.id,
                  workspace_id: data.workspace?.id,
                  setup_complete: true,
                  mapping_complete: true,
                  events_received: true,
                  is_active: true,
                });
                await updateWorkspace({
                  workspace_id: data.workspace?.id,
                  setup_complete: true,
                });
                // if (process.env.REACT_APP_HE_WORKSPACE_KEY && process.env.REACT_APP_HE_DATA_KEY) {
                //   window?.hyperengage(
                //     "track",
                //     "onboarding_completed",
                //     {
                //       properties: {
                //         completed_by_user_id: integrations?.created_by_user_id,
                //         source_name: integrations.source_name,
                //         integration_id: integrations.id,
                //         workspace_id: data.workspace?.id,
                //       },
                //     },
                //   );
                // }
                navigate(`/home/${data.workspace?.unique_identifier}/headquarters`, { replace: true, state: { setupComplete: true } });
              }
            }}
          />
        </span>
      </div>
    </nav>
  );
};

export default NativeSteps;
