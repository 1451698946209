/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  FunnelIcon, PencilIcon, PlusCircleIcon, PlusIcon,
} from "@heroicons/react/20/solid";
import { useAuthInfo } from "@propelauth/react";
import { useMutation } from "@tanstack/react-query";
import {
  useCallback,
  useMemo, useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Divider, Flex, Menu, Text,
} from "@mantine/core";
import uuid from "react-uuid";
import {
  IconBolt, IconChevronDown, IconCircle0Filled, IconCirclePlus,
} from "@tabler/icons-react";
import { queryClient } from "../../../../hooks/QueryHooks";
import {
  createView,
  updateViewInApi,
} from "../../../../services/view/viewEndpoints";
import FilterContainer from "../../../../shared/filter/FilterContainer";
import HeButton from "../../../../shared/ui/buttons/HeButton";
import MultiSelectDropdown from "../../../../shared/ui/dropdown/MultiSelectDropdown";
import SortDropdown from "../../../../shared/ui/dropdown/SortDropdown";
import BasicSelect from "../../../../shared/ui/inputs/select/BasicSelect";
import useViewStore from "../../../../store/view/viewStore";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";
import notify from "../../../../utils/notify";
import { getLargerTimeWindow } from "../../../../utils/dateConverter";
import SignalFilterContainer from "../../signals/signal-overview/components/SignalFilterContainer";
import CreateView from "../../components/CreateView";
import MButton from "../../../../shared/ui/buttons/MButton";
import useSignalStore from "../../../../store/signal/signalStore";
import { Metrics, Traits } from "../../../../services/data-index/dataIndexEndpoints.interface";
import { View } from "../../../../services/view/viewEndpoints.interface";

type Props = {
  count: number;
  currentView: View;
  traits?: {
    list: Traits[],
    obj: {
      [key: string]: Traits
    }
  };
  metrics?: {
    list: Metrics[],
    obj: {
      [key: string]: Metrics
    }
  };
};

const AccountControls = ({
  count,
  currentView,
  traits,
  metrics,
}: Props) => {
  const [create, setCreate] = useState(false);
  const {
    updateView, addPredicate, editPredicate, deletePredicate,
    setMetrics, setTimeWindow, setSelectedFields, addFilter, deleteFilter,
  } = useViewStore();
  const [showFilter, setShowFilter] = useState(true);
  const [showHidden, setShowHidden] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const { user } = useAuthInfo();
  const currentWorkspace = useWorkspaceStore((state) => state.workspace);
  const navigate = useNavigate();
  const timeframes = useMemo(() => ([
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "Last 90 days",
    "Last 180 days",
    "Last 365 days",
    "All time",
  ]).map((key, id) => ({ id: id + 1, label: key, name: key.toLowerCase().split(" ").join("_") })), []);

  const createViewMutation = useMutation({
    mutationFn: createView,
  });

  const updateViewMutation = useMutation({
    mutationFn: updateViewInApi,
  });

  const selectedTimeFrame = useMemo(
    () => timeframes.find((item) => item.name === currentView?.metrics_time_frame) || timeframes[0],
    [currentView?.metrics_time_frame],
  );
  const selectedColumns = useMemo(
    () => currentView?.selected_columns || [],
    [currentView?.selected_columns],
  );

  const selectedMetrics = useMemo(
    () => currentView?.metrics || [],
    [currentView?.metrics],
  );

  const sort = useMemo(
    () => {
      const selectedField = { ...traits?.obj, ...metrics?.obj }[currentView?.sort_fields?.field];
      return {
        name: selectedField?.display_name,
        type: selectedField?.data_type,
        attributeType: selectedField?.attribute_type,
        value: currentView?.sort_fields?.field,
        order: currentView?.sort_fields?.order,
        sortType: currentView?.sort_fields?.type,
        dataType: selectedField?.data_type,
        fieldType: selectedField?.entity_type || "default",
      };
    },
    [currentView?.sort_fields?.field, traits],
  );

  const filteredTraits = useMemo(
    () => traits?.list?.filter((item: any) => !item.is_hidden),
    [metrics?.list?.length],
  );

  const filteredMetrics = useMemo(
    () => metrics?.list?.filter((item: any) => !item.is_hidden),
    [metrics?.list?.length],
  );

  const handleOpenFilters = () => {
    setShowFilter((state) => !state);
    setShowHidden(false);
    setShowSort(false);
  };

  const handleNewView = (name: string, type: string, isPublic: boolean) => {
    createViewMutation.mutate({
      ...currentView,
      filters: { ...currentView?.filters, predicates: currentView?.filters?.predicates?.filter((item: any) => item.field !== "") },
      owner_id: user!.userId,
      workspace_id: currentWorkspace?.id,
      view_name: name,
      is_public: isPublic,
      is_precreated: false,
    }, {
      onSuccess: (data: any) => {
        queryClient.setQueryData(["views", currentWorkspace.id], (oldData: any) => ({
          ...oldData,
          views: [...oldData.views,
            data?.data,
          ],
        }));
        if (process.env.REACT_APP_HE_WORKSPACE_KEY && process.env.REACT_APP_HE_DATA_KEY) {
          window?.hyperengage(
            "track",
            "new_view_created",
            {
              properties: {
                view_name: name,
                workspace_id: currentWorkspace?.id,
                owner_id: user?.userId,
                is_public: isPublic,
              },
            },
          );
        }
        notify({
          type: "success",
          messageList: ["View created successfully"],
        });
        navigate(`/home/${currentWorkspace?.unique_identifier}/view/${data?.data?.id}`);
      },
      onError: (error: any) => {
        if (error.response?.status !== 0) {
          notify({
            heading: "Unknown error.",
            type: "failure",
            messageList: [
              "An unknown error occurred while creating the new view. Please try again later or contact our support team for assistance.",
            ],
          });
        }
      },
    });
  };

  const handleViewSave = () => {
    updateViewMutation.mutate({
      ...currentView,
      filters: { ...currentView?.filters, predicates: currentView?.filters?.predicates?.filter((item: any) => item.field !== "") },
      workspace_id: currentWorkspace.id,
    }, {
      onSuccess: (data: any) => {
        queryClient.setQueryData(["views", currentWorkspace.id], (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            views: oldData?.data?.views
              ?.map((item: any) => (item.id === data?.data?.id ? data?.data : item)),
          },
        }));
        if (process.env.REACT_APP_HE_WORKSPACE_KEY && process.env.REACT_APP_HE_DATA_KEY) {
          window?.hyperengage(
            "track",
            "view_updated",
            {
              properties: {
                view_name: currentView?.view_name,
                is_public: currentView?.is_public,
                workspace_id: currentWorkspace?.id,
                entity_type: currentView?.entity_relation,
              },
            },
          );
        }
        notify({
          type: "success",
          messageList: ["View updated successfully"],
        });
      },
      onError: (error: any) => {
        if (error.response?.status !== 0) {
          notify({
            heading: "Unknown error.",
            type: "failure",
            messageList: [
              "An unknown error occurred while creating the new view. Please try again later or contact our support team for assistance.",
            ],
          });
        }
      },
    });
  };

  const { setAllFilters, setName } = useSignalStore();

  const handleNewSignal = () => {
    setName(currentView?.view_name);
    setAllFilters(
      currentView?.filters,
    );
    navigate(`/home/${currentWorkspace?.unique_identifier}/signals/new`);
  };

  const handleHidden = () => {
    setShowHidden((state) => !state);
    setShowFilter(false);
    setShowSort(false);
  };

  const handleSort = () => {
    setShowSort((state) => !state);
    setShowFilter(false);
    setShowHidden(false);
  };

  const handleTimeFrame = (timeframe: any) => {
    updateView({
      metrics_time_frame: timeframe?.name,
    });
  };

  const handleFilterAdd = () => {
    const filters = currentView
      ?.filters?.predicates?.length ? currentView?.filters
        ?.predicates[currentView.filters.predicates.length - 1]?.predicates : [];
    if (filters?.length === 0 || filters[0].field !== "") {
      addFilter({
        query_type: "AND",
        predicates: [{
          field: "",
          operator: "",
          value: undefined,
          type: "Text",
          entity_type: "column",
          entity_field_type: "default",
        }],
      });
    }
  };

  const handlePredicateAdd = (filterId?: any) => {
    const filt = currentView?.filters?.predicates[filterId]?.predicates;
    if (filt?.length === 0 || filt[filt.length - 1].field !== "") {
      addPredicate(
        {
          field: "",
          operator: "",
          value: undefined,
          type: "Text",
          entity_type: "column",
          entity_field_type: "",
        },
        filterId,
      );
    }
  };

  const handlePredicateClear = (filterId?: any) => {
    deleteFilter(filterId);
  };

  const handlePredicateChange = (
    field: Traits | Metrics | "",
    operator: string,
    value: any,
    type: string,
    entity_type: string,
    arrayId: number,
    filterId: number,
    time_window?: string,
  ) => {
    if (field !== "") {
      const fieldName = field?.object_definition ? `${field?.object_definition?.slug_name}.${field?.data_key_slug}` : field?.data_key_slug;
      editPredicate({
        field: fieldName,
        operator,
        value,
        type: field?.data_type,
        entity_type: entity_type || "column",
        entity_field_type: field?.entity_type || "default",
        time_window,
      }, arrayId, filterId);
      let newColumns: any = [];
      const newMetrics: any = (!selectedMetrics.includes(fieldName) && entity_type !== "column" && fieldName !== "") ? [...selectedMetrics, fieldName] : selectedMetrics;
      if (currentView?.entity_relation === "accounts" && fieldName?.startsWith("accounts.")) {
        newColumns = (!selectedColumns.find((item) => item.name === fieldName) && entity_type === "column") ? [...selectedColumns, {
          name: fieldName,
          field_type: field?.entity_type,
          data_type: field?.data_type,
        }] : selectedColumns;
        setSelectedFields(newColumns);
      } else if (currentView?.entity_relation === "users" && fieldName?.startsWith("users.")) {
        newColumns = (selectedColumns.find((item) => item.name === fieldName) && entity_type === "column") ? [...selectedColumns, {
          name: fieldName,
          field_type: field?.entity_type,
          data_type: field?.data_type,
        }] : selectedColumns;
        setSelectedFields(newColumns);
      }
      setMetrics(newMetrics);
      setTimeWindow(getLargerTimeWindow(currentView?.metrics_time_frame, time_window));
    }
  };

  const handlePredicateRemove = (id: number, filterId?: any) => {
    deletePredicate(id, filterId);
  };

  const onSelectSort = useCallback(
    (field: any, dir: any) => {
      updateView({
        sort_fields: {
          field: field?.value, order: dir?.order, type: dir?.type || "property", data_type: field?.dataType, field_type: field?.fieldType,
        },
      });
    },
    [currentView],
  );

  // Logic for selecting specific columns
  const onSelectColumn = (item: any) => {
    // Checking if the column is a metric, then setting it in metrics
    if (item.attribute_type === "metric_traits") {
      updateView({
        ...currentView,
        metrics: selectedMetrics.includes(item.data_key_slug)
          ? selectedMetrics.filter((name: any) => name !== item.data_key_slug)
          : [...selectedMetrics, item.data_key_slug],
      });
      // Else we are setting it in selected columns
    } else {
      updateView({
        ...currentView,
        selected_columns: selectedColumns?.find((col) => col.name === `${item.object_definition.slug_name}.${item.data_key_slug}`)
          ? selectedColumns.filter((col) => col.name !== `${item.object_definition.slug_name}.${item.data_key_slug}`)
          : [...selectedColumns, {
            name: `${item.object_definition.slug_name}.${item.data_key_slug}`,
            field_type: item.entity_type,
            data_type: item.data_type,
          }],
      });
    }
  };

  return (
    <>
      <div className="sm:flex items-center px-5 py-2">
        <div className="sm:flex-auto items-center">
          <h2 className="text-[16px] font-normal text-gray-800">
            {count}
            {" "}
            {currentView?.entity_relation || "accounts"}
            {" "}
            with Profiles
          </h2>
        </div>
        <div className="flex gap-2 justify-end flex-wrap lg:flex-nowrap items-end">
          <HeButton
            size="sm"
            color="white"
            onClick={handleOpenFilters}
            text={`${!showFilter ? "Show Filters " : "Hide Filters"}${
              currentView?.filters?.predicates?.length
                ? ` (${currentView?.filters?.predicates?.length})`
                : ""
            }`}
            LeadingIcon={FunnelIcon}
          />
          <MultiSelectDropdown
            selected={[...selectedColumns, ...selectedMetrics]}
            setSelected={onSelectColumn}
            isOpen={showHidden}
            setIsOpen={setShowHidden}
            list={filteredTraits}
            metricsList={filteredMetrics}
            position="bottom-end"
          >
            <div>
              <HeButton
                size="sm"
                color="white"
                onClick={handleHidden}
                text={`Edit Columns ${
                  currentView?.selected_columns?.length
                    ? ` (${
                      currentView?.selected_columns?.filter(
                        (item: any) => !traits?.obj[item]?.is_hidden,
                      ).length
                    })`
                    : ""
                }`}
                LeadingIcon={PencilIcon}
              />
            </div>
          </MultiSelectDropdown>

          {sort?.name && (
          <SortDropdown
            selectedColumns={[...selectedColumns, ...selectedMetrics]}
            selectSort={sort}
            isOpen={showSort}
            setIsOpen={setShowSort}
            list={[...(filteredTraits || []), ...(filteredMetrics || [])]}
            obj={{ ...traits?.obj, ...metrics?.obj }}
            onDone={onSelectSort}
          >
            <HeButton
              size="sm"
              color="white"
              onClick={handleSort}
              text={`Sort: ${
                (traits?.obj && metrics?.obj) ? { ...traits.obj, ...metrics.obj }[sort?.value]?.display_name : ""
              }`}
            />
          </SortDropdown>
          )}
          <div className="items-center">
            <p className="text-xs text-gray-500 mr-2">Show Usage From: </p>
            <BasicSelect
              textClass="text-gray-600 text-xs py-0.5"
              containerClass="min-w-[140px]"
              placeholder="Timeframe"
              list={timeframes}
              selectedItem={selectedTimeFrame}
              setSelectedItem={handleTimeFrame}
            />
          </div>
        </div>
      </div>
      {showFilter && (
      <div className="px-4">
        {currentView?.filters?.predicates?.map((filter: any, index: number) => (
          <div key={index + currentView.entity_relation}>
            <SignalFilterContainer
              key={index + currentView.entity_relation}
              entityRelation={currentView?.entity_relation}
              time_window={currentView?.metrics_time_frame}
              selectedFilters={filter.predicates}
              handleAdd={handlePredicateAdd}
              handleClear={handlePredicateClear}
              handleRemove={handlePredicateRemove}
              handleFilter={handlePredicateChange}
              filterId={index}
            />
            {currentView?.filters?.predicates?.length === index + 1 ? null : (
              <Flex h={50} key={uuid()} direction="column" align="center" justify="center">
                <Divider w={2} className="mx-auto" orientation="vertical" size="lg" h={15} />
                <Text size="xs" c="text.4" weight={600}>- OR -</Text>
                <Divider w={2} className="mx-auto" orientation="vertical" size="lg" h={15} />
              </Flex>
            )}
          </div>
        ))}
        <Flex pb={10} className="mt-4" justify="space-between">
          <button
            type="button"
            onClick={() => { handleFilterAdd(); }}
            className="flex rounded-lg hover:text-purple-800 items-center p-1 text-xs font-medium text-purple-500"
          >
            <PlusIcon className="mr-[1px] flex-shrink-0 h-[20px] w-[20px]" />
            Add Condition Group
          </button>
          <Menu shadow="md" position="bottom-end" width={200}>
            <Menu.Target>
              <div>
                <MButton size="sm" color="purple" rightIcon={<IconChevronDown className="w-5 h-5" />}>Save Options</MButton>
              </div>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                icon={<IconCirclePlus className="text-gray-400 w-4 h-4" />}
                onClick={
                currentView?.is_precreated
                  ? () => {
                    setCreate(true);
                  }
                  : handleViewSave
              }
              >
                {currentView?.is_precreated ? "Create View" : "Save View"}
              </Menu.Item>
              <Menu.Item
                icon={<IconBolt className="text-gray-400 w-4 h-4" />}
                onClick={() => {
                  handleNewSignal();
                }}
              >
                Create Signal
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          {create && (
          <CreateView
            open={create}
            onClick={handleNewView}
            setOpen={setCreate}
            defaultType={currentView?.entity_relation === "accounts" ? "accounts" : "users"}
            disableType
          />
          )}
        </Flex>
      </div>
      )}
    </>
  );
};

export default AccountControls;
