import { authorizedApiClient } from "./config";

type Integration = {
  workspace_id: string,
  workspace_identifier:string,
  created_by_user_id: string,
  source_name: string,
  events_received?: boolean,
  setup_complete?: boolean,
  mapping_complete?: boolean,
  mapping_schema?: any,
  create_new_users_enabled?: boolean,
  create_new_accounts_enabled?: boolean,
  is_active?: boolean,
};

export const createIntegration = async ({
  workspace_identifier,
  workspace_id,
  created_by_user_id,
  source_name,
  events_received = false,
  setup_complete = false,
  mapping_complete = false,
  mapping_schema = {},
  create_new_users_enabled = true,
  create_new_accounts_enabled = true,
  is_active = true,
}: Integration) => authorizedApiClient.post(`/${workspace_id}/integrations`, {
  workspace_id,
  workspace_identifier,
  is_active,
  created_by_user_id,
  source_name,
  events_received,
  setup_complete,
  mapping_complete,
  mapping_schema,
  create_new_users_enabled,
  create_new_accounts_enabled,
});

type UpdateIntegration = {
  workspace_id: string,
  created_by_user_id: string,
  source_name: string,
  events_received?: boolean,
  setup_complete?: boolean,
  mapping_complete?: boolean,
  mapping_schema?: any,
  create_new_users_enabled?: boolean,
  create_new_accounts_enabled?: boolean,
  is_active?: boolean,
  id: string,
};

export const updateIntegration = async ({
  workspace_id,
  created_by_user_id,
  is_active,
  source_name,
  events_received,
  setup_complete,
  mapping_complete,
  mapping_schema = {},
  create_new_users_enabled,
  create_new_accounts_enabled,
  id,
}: UpdateIntegration) => {
  const data: any = {};
  if (events_received !== undefined) data.events_received = events_received;
  if (setup_complete !== undefined) data.setup_complete = setup_complete;
  if (mapping_complete !== undefined) data.mapping_complete = mapping_complete;
  if (mapping_schema !== undefined) data.mapping_schema = mapping_schema;
  if (create_new_users_enabled !== undefined) {
    data.create_new_users_enabled = create_new_users_enabled;
  }
  if (create_new_accounts_enabled !== undefined) {
    data.create_new_accounts_enabled = create_new_accounts_enabled;
  }

  return authorizedApiClient.put(`/${workspace_id}/integrations`, {
    workspace_id,
    id,
    is_active,
    created_by_user_id,
    source_name,
    ...data,
  });
};

type GetEvents = {
  integration_name?: string,
  token?: string,
  workspace_id: string,
};

export const getIntegrationEvents = async ({
  workspace_id,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  integration_name,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  token,
}: GetEvents) => authorizedApiClient.get(`workspace/${workspace_id}/event_cache?limit=10&skip=0`);

export const getAllIntegration = async ({
  workspace_id,
}: { workspace_id: string }) => authorizedApiClient.get(`/${workspace_id}/integrations/all`);

export const getSlackIntegration = async ({
  workspace_id,
}: { workspace_id: string }) => authorizedApiClient.get(`/${workspace_id}/integrations/slack`);

export const getSlackUsers = async ({
  workspace_id,
}: { workspace_id: string }) => authorizedApiClient.get(`/${workspace_id}/integrations/slack/users`);

export const getSlackChannels = async ({
  workspace_id,
}: { workspace_id: string }) => authorizedApiClient.get(`/${workspace_id}/integrations/slack/channels`);
