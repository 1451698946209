/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { PlusIcon } from "@heroicons/react/20/solid";
import { useTraitsAndMetrics } from "../../../../../hooks/QueryHooks";
import RowEntitySelect from "../../../../../shared/filter/RowEntitySelect";
import useWorkspaceStore from "../../../../../store/workspace/workspaceStore";

type Props = {
  selectedFilters?: any;
  handleClear?: any;
  handleFilter?: any;
  handleRemove?: any,
  handleAdd?: any;
  filterId?: number;
  time_window?: string;
  entityRelation?: string;
};

const SignalFilterContainer = ({
  selectedFilters,
  time_window,
  handleClear,
  handleFilter,
  handleRemove,
  handleAdd,
  filterId,
  entityRelation,
}: Props) => {
  const workspace = useWorkspaceStore((state) => state.workspace);
  const { data: traitsAndMetrics }: any = useTraitsAndMetrics({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });
  return (
    <div className="rounded-xl sm:flex sm:items-center px-5 py-5 w-full bg-gray-100">
      <div className="overflox-auto flex flex-col w-full gap-2">
        {selectedFilters?.map((filter: any, index: number) => (
          <RowEntitySelect
            filter={filter}
          // eslint-disable-next-line no-nested-ternary
            entityRelation={entityRelation}
            time_window={time_window}
            key={`${filter?.field} + ${index}`}
            onRemove={handleRemove}
            traitsAndMetrics={traitsAndMetrics}
            currentKey={index}
            filterId={filterId!}
            onCompleteFilter={handleFilter}
          />
        ))}
        <div className="w-full -mb-2 flex justify-between pt-2">
          <button
            type="button"
            onClick={() => { handleAdd(filterId); }}
            className="flex rounded-lg hover:text-gray-800 items-center p-1 text-xs font-medium text-gray-500"
          >
            <PlusIcon className="mr-[1px] flex-shrink-0 h-[20px] w-[20px]" />
            Add Condition
          </button>
          <button
            type="button"
            onClick={() => { handleClear(filterId); }}
            className="flex rounded-lg hover:text-gray-800 items-center p-1 text-xs font-medium text-gray-500"
          >
            Clear All
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignalFilterContainer;
