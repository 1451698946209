/* eslint-disable @typescript-eslint/no-floating-promises */
import { format } from "date-fns";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAccount, useTraitsAndMetrics, useWorkspaceMembers } from "../../../../hooks/QueryHooks";
import TraitSource from "../../../../shared/trait/TraitSource";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";

const flattenProperties = (data: any) => {
  if (data.properties) {
    Object.keys(data.properties).forEach((key) => {
      data[key] = data.properties[key];
    });

    // Delete the "properties" key from the main object
    delete data.properties;
  }
};

const AccountTraits = () => {
  const { accountId }: any = useParams();
  const workspaceId = useWorkspaceStore((state) => state.workspace?.id);
  const { data: accountProps }: any = useAccount({
    workspaceId,
    enabled: !!workspaceId,
    accountId,
  });
  const { data: memberData }: any = useWorkspaceMembers({ workspaceId });

  const account = useMemo(() => {
    if (accountProps) {
      flattenProperties(accountProps);
      return accountProps;
    }
    return {};
  }, [accountProps]);

  const { data: traits } = useTraitsAndMetrics({
    workspaceId,
    enabled: !!workspaceId,
  });
  const shownTraits: any = useMemo(() => Object.entries(account || {})
    .reduce((obj: any, [trKey, trValue]: any) => {
      if ((trKey === "csm_id" || trKey === "ae_id") && memberData) {
        const csm = memberData?.users?.find((member: any) => member?.user_id === account[trKey]);
        obj.native[`${trKey}_data`] = {
          display_name: trKey === "csm_id" ? "CSM" : "Account Executive",
          name: trKey === "csm_id" ? "CSM" : "Account Executive",
          type: "user",
          val: {
            name: `${csm?.first_name} ${csm?.last_name}`,
            email: csm?.email,
            img: csm?.picture_url,
            externalId: csm?.user_id,
          },
        };
        delete obj.native[trKey];
        return obj;
      }
      if (trKey.includes("account_owner")) {
        obj.native.account_owner = {
          display_name: "Account Owner",
          name: "account_owner",
          type: "user",
          val: {
            email: account.account_owner_email,
            name: account.account_owner_name,
            first_name: account.account_owner_first_name,
            last_name: account.account_owner_last_name,
            last_seen: account.account_owner_last_seen,
          },
        };
        delete obj.native.account_owner_email;
        delete obj.native.account_owner_name;
        delete obj.native.account_owner_first_name;
        delete obj.native.account_owner_last_name;
        delete obj.native.account_owner_last_seen;
        return obj;
      } if (trKey.includes("total_user_count")) {
        obj.native.total_user_count = {
          display_name: "Total User Count",
          name: "total_user_count",
          val: trValue,
        };
        return obj;
      }
      const selectedTrait = traits?.accountTraits?.obj[`accounts.${trKey}`];
      if (!selectedTrait?.is_hidden) {
        let data = {};
        if (selectedTrait?.data_type === "Date") {
          data = {
            ...selectedTrait,
            val: trValue ? format(new Date(trValue), "do MMMM yyyy") : undefined,
          };
        } else {
          data = {
            ...selectedTrait,
            val: trValue,
          };
        }
        if (!selectedTrait?.integration || selectedTrait?.integration?.source_name === "hyperengage") {
          obj.native[trKey] = data;
        } else if (selectedTrait?.integration?.source_name === "segment") {
          obj.segment[trKey] = data;
        }
      }
      return obj;
    }, { segment: {}, native: {} }), [traits?.accountTraits, account, memberData]);

  return (
    <div className="px-12 py-8 relative flex flex-col gap-8 overflow-auto">
      <div className="flex mt-2 justify-between items-center">
        <h2 className="text-gray-900 text-[20px] font-semibold">Traits</h2>
      </div>
      {Object.keys(shownTraits?.native).length ? shownTraits?.native && <TraitSource source="hyperengage" data={shownTraits.native} /> : undefined}
      {Object.keys(shownTraits?.segment).length ? <TraitSource source="segment" data={shownTraits.segment} /> : undefined}
    </div>
  );
};

export default AccountTraits;
