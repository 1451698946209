/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import {
  Flex, LoadingOverlay, Paper, ScrollArea,
  Text,
} from "@mantine/core";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SlackIcon } from "../../../assets/images/slack.svg";
import {
  queryClient, useAllIntegrations, useCurrentUser, useSlackIntegration,
} from "../../../hooks/QueryHooks";
import { createIntegration } from "../../../services/integrationEndpoint";
import IntegrationContainer from "../../../shared/integration/IntegrationContainer";
import ApiIcon from "../../../shared/ui/icons/ApiIcon";
import SegmentIcon from "../../../shared/ui/icons/SegmentIcon";
import useWorkspaceStore from "../../../store/workspace/workspaceStore";
import { ReactComponent as HubspotIcon } from "../../../assets/images/hubspot.svg";

const IntegrationsPage = () => {
  const workspace: any = useWorkspaceStore((state) => state.workspace);

  const { data: allIntegrations }: any = useAllIntegrations({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  const { data: slackIntegration, isLoading }: any = useSlackIntegration({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  const navigate = useNavigate();
  const user: any = useCurrentUser({});
  const segmentIntegration = useMemo(() => allIntegrations?.filter((item: any) => item?.source_name === "segment_api"), [allIntegrations]);
  const nativeIntegration = useMemo(() => allIntegrations?.filter((item: any) => item?.source_name === "native"), [allIntegrations]);

  const apiClick = async () => {
    if (!nativeIntegration?.length) {
      try {
        await createIntegration({
          workspace_identifier: workspace.unique_identifier,
          source_name: "native",
          workspace_id: workspace.id,
          created_by_user_id: user.data.user_id,
          is_active: true,
        });
        queryClient.invalidateQueries(["integrations"]);
      } catch (err) {
        return;
      }
    }
    navigate(`/onboarding/workspace/${workspace.unique_identifier}/integrations/hyperengage-api`);
  };

  const segmentClick = async () => {
    if (!segmentIntegration?.length) {
      try {
        await createIntegration({
          workspace_identifier: workspace.unique_identifier,
          source_name: "segment_api",
          workspace_id: workspace.id,
          created_by_user_id: user.data.user_id,
          is_active: true,
        });
        queryClient.invalidateQueries(["integrations"]);
      } catch (err) {
        return;
      }
    }
    navigate(`/onboarding/workspace/${workspace.unique_identifier}/integrations/segment-api`);
  };

  return (
    <Paper className="px-12 py-8 flex flex-col gap-1 relative bg-white h-[100vh] overflow-hidden">
      <Text className="text-gray-900 text-[25px] font-bold">Integrations</Text>
      <Text className="text-gray-600">Connect integrations to enrich visitor and company data in Hyperengage or to send data from Hyperengage to your favorite tools.</Text>
      <ScrollArea mt={20}>
        <Text c="text.9" mb={10} weight={600}>CDP</Text>
        <Flex wrap="wrap" align="stretch" gap={20}>
          <LoadingOverlay loaderProps={{ size: "md" }} c="main.5" visible={isLoading} overlayBlur={2} />
          <IntegrationContainer
            Icon={<ApiIcon className="!w-[60px] !h-[60px]" />}
            title="Hyperengage Tracking"
            desc="Integrate with Hyperengage to import your data here for better management"
            onClick={apiClick}
            text={nativeIntegration?.length ? (nativeIntegration[0]?.setup_complete ? "Edit" : "Setup Pending") : "Connect"}
            badge={nativeIntegration?.length && nativeIntegration[0]?.setup_complete ? "Completed" : "Setup Pending"}
          />
          <IntegrationContainer
            title="Segment"
            Icon={<SegmentIcon className="shrink-0 !w-[60px] !h-[60px]" />}
            desc="Install Hyperengage via Segment's client-side Analytics library"
            onClick={segmentClick}
            text={segmentIntegration?.length ? (segmentIntegration[0]?.setup_complete ? "Edit" : "Setup Pending") : "Connect"}
            badge={segmentIntegration?.length && segmentIntegration[0]?.setup_complete ? "Completed" : "Setup Pending"}
          />
        </Flex>
        <Text c="text.9" weight={600} mt={40} mb={10}>Notifications</Text>
        <Flex wrap="wrap" align="stretch" gap={20}>
          <IntegrationContainer
            Icon={<SlackIcon className="w-[60px] !h-[60px]" />}
            title="Slack"
            desc="Receive immediate notifications when a user takes action within your product."
            onClick={() => { navigate("slack"); }}
            text={slackIntegration?.msg === "slack integration found" ? "Edit" : "Connect"}
            badge={slackIntegration?.msg === "slack integration found" ? "Completed" : ""}
          />
        </Flex>
        <Text c="text.9" weight={600} mt={40} mb={10}>CRM</Text>
        <Flex wrap="wrap" align="stretch" gap={20}>
          <IntegrationContainer
            title="Hubspot"
            Icon={<HubspotIcon className="w-[60px] !h-[60px]" />}
            desc="Integrate Hubspot with Hyperengage"
            onClick={() => { navigate("hubspot"); }}
            text={segmentIntegration?.length ? (segmentIntegration[0]?.setup_complete ? "Edit" : "Setup Pending") : "Connect"}
            badge={segmentIntegration?.length && segmentIntegration[0]?.setup_complete ? "Completed" : "Setup Pending"}
          />
        </Flex>
      </ScrollArea>
    </Paper>
  );
};

export default IntegrationsPage;
