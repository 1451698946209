import { create } from "zustand";
import { SelectedColumn } from "../../services/view/viewEndpoints.interface";

// Define your store's state
type Store = {
  signal: {
    name: string;
    description: string;
    trigger: "usage_based" | "event_based";
    entity_relation: "users" | "accounts";
    automation_type: "signal" | "automation";
    is_precreated: boolean;
    filters: {
      query_type: "OR";
      predicates: {
        query_type: "AND";
        predicates: {
          entity_type?: any;
          field: string;
          operator: string;
          value: string | undefined;
          entity_field_type: string;
          type?: string;
          time_window?: string;
        }[];
      }[];
    };
    metrics: any[];
    status: "paused" | "active";
    automation_actions: {
      actions: Array<{
        type: "send_to_inbox" | "send_to_slack" | "send_to_email";
        assign_to: string;
        assign_to_key: string;
        channel_id?: string;
        slack_user_id?: string;
        message_type?: "channel" | "direct";
        message?: string;
        to?: string;
        subject?: string;
        body?: string;
      }>;
      suppression_period: "all_time" | "daily";
      playbook_id?: string;
    };
    time_window: string;
    selected_fields: SelectedColumn[];
    time_zone: string;
    owner_id: string;
    workspace_id: string;
    responseData: any;
  };
  setSignal: (entity: Store["signal"]) => void;
  setName: (name: string) => void;
  setTrigger: (trigger: Store["signal"]["trigger"]) => void;
  setEntityRelation: (entity_relation: Store["signal"]["entity_relation"]) => void;
  setAutomationType: (automation_type: Store["signal"]["automation_type"]) => void;
  addFilter: (filter: Store["signal"]["filters"]["predicates"][0]) => void;
  editFilter: (filter: Store["signal"]["filters"]["predicates"][0], index: number) => void;
  deleteFilter: (index: number) => void;
  clearFilter: () => void;
  clearSignal: () => void;
  setMetrics: (metrics: Store["signal"]["metrics"]) => void;
  setStatus: (status: Store["signal"]["status"]) => void;
  setTimeWindow: (time_window: Store["signal"]["time_window"]) => void;
  setSelectedFields: (selected_fields: Store["signal"]["selected_fields"]) => void;
  setTimeZone: (time_zone: Store["signal"]["time_zone"]) => void;
  editPredicate: (predicate: Store["signal"]["filters"]["predicates"][0]["predicates"][0], index: number, filterIndex: number) => void;
  addPredicate: (predicate: Store["signal"]["filters"]["predicates"][0]["predicates"][0], filterIndex: number) => void;
  deletePredicate: (index: number, filterIndex: number) => void;
  setSuppressionPeriod: (suppression_period: Store["signal"]["automation_actions"]["suppression_period"]) => void;
  createAction: (action: Store["signal"]["automation_actions"]["actions"][0]) => void;
  deleteAction: (type: Store["signal"]["automation_actions"]["actions"][0]["type"]) => void;
  updateAction: (action: Store["signal"]["automation_actions"]["actions"][0], type: Store["signal"]["automation_actions"]["actions"][0]["type"]) => void;
  setPlaybook: (playbook_id: any) => void;
  setAllFilters: (filters: any) => void;
  setResponseData: (responseData: any) => void;
};

const useSignalStore = create<Store>((set) => ({
  signal: {
    name: "",
    description: "",
    trigger: "usage_based",
    entity_relation: "accounts",
    automation_type: "signal",
    is_precreated: false,
    filters: {
      query_type: "OR",
      predicates: [{
        query_type: "AND",
        predicates: [{
          field: "",
          operator: "",
          value: "",
          entity_field_type: "",
        }],
      }],
    },
    metrics: [],
    status: "paused",
    automation_actions: {
      actions: [],
      suppression_period: "all_time",
    },
    time_window: "last_30_days",
    selected_fields: [{
      name: "accounts.name",
      data_type: "Text",
      field_type: "default",
    }, {
      name: "accounts.last_seen",
      data_type: "Date",
      field_type: "default",
    }],
    time_zone: "",
    owner_id: "",
    workspace_id: "",
    responseData: {},
  },
  setSignal: (signal) => set({ signal }),
  setName: (name) => set((state) => ({ signal: { ...state.signal, name } })),
  setTrigger: (trigger) => set((state) => ({ signal: { ...state.signal, trigger } })),
  setEntityRelation: (entity_relation) => set(
    (state) => ({ signal: { ...state.signal, entity_relation } }),
  ),
  setAutomationType: (automation_type) => set(
    (state) => ({ signal: { ...state.signal, automation_type } }),
  ),
  setResponseData: (responseData) => set(
    (state) => ({ signal: { ...state.signal, responseData } }),
  ),
  addFilter: (filter) => set(
    (state) => ({
      signal: {
        ...state.signal,
        filters: {
          ...state.signal.filters,
          predicates: [...state.signal.filters.predicates, filter],
        },
      },
    }),
  ),
  editFilter: (filter, index) => set((state) => {
    const filters = [...state.signal.filters.predicates];
    filters[index] = filter;
    return {
      signal: {
        ...state.signal,
        filters: {
          ...state.signal.filters,
          predicates: filters,
        },
      },
    };
  }),
  deleteFilter: (index) => set((state) => {
    const filters = [...state.signal.filters.predicates];
    filters.splice(index, 1);
    return {
      signal: {
        ...state.signal,
        filters: {
          ...state.signal.filters,
          predicates: filters,
        },
      },
    };
  }),
  clearFilter: () => set((state) => ({
    signal: {
      ...state.signal,
      filters: {
        query_type: "OR",
        predicates: [{
          query_type: "AND",
          predicates: [{
            field: "",
            operator: "",
            value: "",
            entity_field_type: "",
          }],
        }],
      },
    },
  })),
  editPredicate: (predicate, index, filterIndex) => set((state) => {
    const filters = [...state.signal.filters.predicates];
    const predicates = filters[filterIndex]?.predicates ? [...filters[filterIndex].predicates] : [];
    predicates[index] = predicate;
    filters[filterIndex] = {
      query_type: "AND",
      predicates,
    };
    return {
      signal: {
        ...state.signal,
        filters: {
          ...state.signal.filters,
          predicates: filters,
        },
      },
    };
  }),
  addPredicate: (predicate, filterIndex) => set((state) => {
    const filters = [...state.signal.filters.predicates];
    filters[filterIndex].predicates.push(predicate);
    return {
      signal: {
        ...state.signal,
        filters: {
          ...state.signal.filters,
          predicates: filters,
        },
      },
    };
  }),
  deletePredicate: (index, filterIndex) => set((state) => {
    const filters = [...state.signal.filters.predicates];
    filters[filterIndex].predicates.splice(index, 1);
    return {
      signal: {
        ...state.signal,
        filters: {
          ...state.signal.filters,
          predicates: filters,
        },
      },
    };
  }),
  clearSignal: () => set(() => ({
    signal: {
      name: "",
      description: "",
      trigger: "usage_based",
      entity_relation: "accounts",
      automation_type: "signal",
      is_precreated: false,
      filters: {
        query_type: "OR",
        predicates: [{
          query_type: "AND",
          predicates: [{
            field: "",
            operator: "",
            value: "",
            entity_field_type: "",
          }],
        }],
      },
      metrics: [],
      status: "paused",
      automation_actions: {
        actions: [],
        suppression_period: "all_time",
      },
      time_window: "last_30_days",
      selected_fields: [{
        name: "accounts.name",
        data_type: "Text",
        field_type: "default",
      }, {
        name: "accounts.last_seen",
        data_type: "Date",
        field_type: "default",
      }],
      time_zone: "",
      owner_id: "",
      workspace_id: "",
      responseData: {},
    },
  })),
  setSelectedFields: (selected_fields) => set((state) => ({
    signal: {
      ...state.signal,
      selected_fields,
    },
  })),
  setMetrics: (metrics) => set((state) => ({
    signal: {
      ...state.signal,
      metrics,
    },
  })),
  setTimeWindow: (time_window) => set((state) => ({
    signal: {
      ...state.signal,
      time_window,
    },
  })),
  setStatus: (status) => set((state) => ({
    signal: {
      ...state.signal,
      status,
    },
  })),
  setTimeZone: (time_zone) => set((state) => ({
    signal: {
      ...state.signal,
      time_zone,
    },
  })),
  setSuppressionPeriod: (suppression_period) => set((state) => ({
    signal: {
      ...state.signal,
      automation_actions: {
        ...state.signal.automation_actions,
        suppression_period,
      },
    },
  })),
  setAllFilters: (filters: any) => set((state) => ({
    signal: {
      ...state.signal,
      filters,
    },
  })),

  createAction: (action) => set((state) => {
    const actions = [...state.signal.automation_actions.actions];
    actions.push(action);
    return {
      signal: {
        ...state.signal,
        automation_actions: {
          ...state.signal.automation_actions,
          actions,
        },
      },
    };
  }),
  deleteAction: (type) => set((state) => {
    const actions = [...state.signal.automation_actions.actions]
      .filter((action) => action.type !== type);
    return {
      signal: {
        ...state.signal,
        automation_actions: {
          ...state.signal.automation_actions,
          actions,
        },
      },
    };
  }),
  setPlaybook: (playbook_id: any) => set((state) => ({
    signal: {
      ...state.signal,
      automation_actions: {
        ...state.signal.automation_actions,
        playbook_id,
      },
    },
  })),
  updateAction: (action, type) => set((state) => {
    let actions;
    if (!state?.signal?.automation_actions?.actions?.find((act) => act?.type === type)) {
      actions = [...state.signal.automation_actions.actions];
      actions.push(action);
    } else {
      actions = [...state.signal.automation_actions.actions].map((act: any) => {
        if (act.type === type) {
          const updatedAct = {
            ...act,
            ...action,
          };
          return updatedAct;
        }
        return act;
      });
    }
    return {
      signal: {
        ...state.signal,
        automation_actions: {
          ...state.signal.automation_actions,
          actions,
        },
      },
    };
  }),
}));

export default useSignalStore;
