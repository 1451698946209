/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from "react";
import { Tooltip } from "@mantine/core";
import MediaIcon from "../../../../shared/ui/icons/MediaIcon";

type Props = {
  choices: { name: string, icon: React.ComponentType<React.SVGProps<SVGSVGElement>> }[],
  onClick: (name: string) => void,
  selected: string,
};

const NativeApiHero = ({ choices, onClick, selected }: Props) => (
  <div className="w-full min-w-0 text-white flex flex-col basis-[40%] gap-4 p-12 sm:p-10 border-r-4 border-gray-100">
    <h3 className="font-semibold text-[16px] text-gray-900">Hyperengage tracking Integration</h3>
    <p className="text-[14px] text-gray-700">
      You can use our tracking script or native SDK's to easily send over account and user traits, also product usage events to monitor and drive action for all of your customers.
    </p>
    <div className="flex gap-3 flex-wrap mt-2">
      {choices.map((item) => (
        <Tooltip key={item.name} withArrow label={item.name}>
          <button type="button" className="bg-white" key={item.name} onClick={() => onClick(item.name)}>
            <span className="sr-only">Integration method choice</span>
            <MediaIcon size="md" Src={item.icon} color="gray" className={`${selected === item.name ? "!text-gray-50 bg-purple-600 border-purple-400 border-purple hover:text-gray-100" : " text-gray-500"} border-2`} />
          </button>
        </Tooltip>
      ))}
    </div>
  </div>
);

export default NativeApiHero;
